//global
import React, { useContext, useEffect, useState } from 'react';
import {
  ComponentRendering,
  LayoutServiceData,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import ProductDetailSliderComponent, {
  SlideProps,
} from 'components/ProductDetailSliderComponent/ProductDetailSliderComponent';
import LinkHelper from 'components/helpers/LinkHelper';
import IconHelper from 'components/helpers/IconHelper';
import { useBreakpoints } from 'src/utils/breakpoints';
import useDictionary from 'src/hooks/useDictionary';
import { apiRequest } from 'src/utils/apiWrapper';
import StoreDetailTab from 'components/StoreDetailTab/StoreDetailTab';
import useOcCart from 'src/hooks/useOcCart';
import { useOcSelector } from 'src/redux/ocStore';
import apiConfig from 'src/utils/apiConfig';
import { ProductUrlData } from 'lib/middleware/plugins/pdp-sku';
import Head from 'next/head';
import BazarVoiceReviews from 'components/BazarVoice/BazarVoiceReviews';
import ComponentContext from 'lib/context/ComponentContext';
import { isProductInWishlist, updateProductInWishlist } from 'src/redux/wishlist';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useRouter } from 'next/router';
import urlConfig from 'src/utils/urlConfig';
import { useAutoshipMessage } from 'src/helpers/utility/AutoshipHelper';
import { BuyerProductWithXp } from 'src/redux/xp';
import useOcCurrentOrder from 'src/hooks/useOcCurrentOrder';
import clsx from 'clsx';
import { Order } from 'ordercloud-javascript-sdk';
import {
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  getProductDetailPath,
  retailUnitMeasureFieldName,
} from 'components/helpers/Constants';
import { patchOrder } from 'src/redux/ocCurrentOrder';

//type
import { useProductPriceForUI } from 'src/hooks/useProductPrice';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { checkProductAssociation } from './fetchProductData';
import { getFirstProductImage, getSortedProductImages } from 'src/helpers/productDetailHelper';
import { extractDomainUrlFromConfig } from 'src/helpers/publicURLTrailingSlash';
import { getProductPriceFromSearch } from 'src/helpers/ProductPriceUI';
import { getSiteName } from 'src/helpers/siteNameHelper';
import { trackAddEvent, trackEntityPageViewEvent } from '@sitecore-search/react';
import Loader from 'components/Loader/Loader';
export type ProductData = BuyerProductWithXp & {
  error?: string;
  SEOMetadata?: {
    Title?: string;
    Description?: string;
    Keywords?: string;
  };
  PCMProduct?: {
    Product?: {
      GuaranteedAnalysisNutrientName?: string;
      Ingredients?: string;
      ProductDetailsBullets?: string[];
    };
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Xp?: any;
  // {
  //   Images?: SlideProps[];
  //   Bullets?: string[];
  //   IngredientsComposition?: string;
  //   USMade: boolean;
  //   RetailUnit: string;
  //   RetailMeasure: string;
  //   Video?: string;
  // };
};
//type
export type ProductDetailProps = {
  props: {
    parentId?: string;
    productSku?: string;
    productData?: ProductData;
    domainUrl?: string;
  };
};
//component variants, added desktop css class in slots as direct in desktop it is not working properly
export const pDPComponentVariants = tv(
  {
    slots: {
      base: 'pDPComponent container',
      title:
        'flex text-heading-desk-large-bold leading-heading-desk-large-bold font-heading-desk-large-bold font-primary',
      learnMoreLink:
        'min-w-fit h-fit text-body-large-bold font-body-large-bold font-primary leading-body-large-bold underline text-color-scale-8-dark',
      productDetailsWrapper: 'flex flex-col w-full',
      productOptionText: 'ml-[6.5px]',
      deliveryOptionsWrapper: 'flex',
      deliveryOptionWrapper: ' h-fit flex flex-col border-2 border-color-mid lg:max-w-[269px]',
      productOptionsWrapper: 'pl-[7px] font-primary',
      productOptionsWrapperForPickup:
        'pl-[7px] font-primary !mb-mob-margin-base-bottom lg:!mb-desk-margin-tight-bottom',
      productOptions: 'flex gap-3 lg:flex-nowrap lg:max-w-[577px] flex-wrap',
      productOption: 'flex flex-col w-full lg:max-w-[279px]',
      productOptionLabel:
        'font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      productOptionDropdown:
        'lg:max-w-[279px] cursor-pointer appearance-none border-2 focus:outline-none w-full h-[48px] border-color-scale-mid',
      dropdownIcon: 'absolute cursor-pointer top-1/2 transform -translate-y-1/2 right-2',
      pickUpOptionLabel:
        'font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      pickUpOptionText:
        'font-primary text-body-large-regular font-body-large-regular leading-body-large-regular',
      changeStoreText:
        'flex w-fit group justify-start items-center font-primary text-body-large-bold font-body-large-bold leading-body-large-bold text-color-text-brand-1 focus:text-color-brand-tertiary-3-base hover:text-color-brand-tertiary-3-base',
      changeStoreIcon:
        'w-4 h-4 [&_svg]:max-w-4 [&_svg]:max-h-4 group-hover:[&>svg>*]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:fill-color-brand-tertiary-3-base [&>svg>*]:fill-color-text-brand-1',
      autoShipOptionWrapper:
        'h-fit flex flex-col w-full border-2 border-color-mid lg:max-w-[270px]',
      autoShipWrapper: 'flex flex-col',
      toolbarIcon: 'h-fit cursor-pointer [&>svg>g>*]:fill-color-text-brand-1',
      autoShipText:
        'relative flex font-primary text-body-large-bold font-body-large-bold leading-body-large-bold lg:ml-desk-space-between-tight-horizontal',
      autoShipLabel:
        'w-full bg-pdp-option-background text-color-text-white text-body-small-regular font-body-small-regular leading-body-small-regular',
      centerDiv: 'flex relative cursor-pointer',
      relativeDiv: 'flex relative',
      tooltipContainer: 'relative w-full h-full p-2 pb-3 lg:p-4',
      tooltipWrapper:
        'absolute top-1/2 -translate-y-1/2 right-[39px] w-[193px] lg:w-[320px] bg-color-background-light text-color-text-black border-2 border-color-border-mid text-sm shadow-[0px_2px_8px_0px_rgba(0,0,0,0.25)] z-10',
      topTooltipWrapper:
        'absolute left-1/2 -translate-x-1/2 bottom-[calc(100%+10px)] w-[193px] lg:w-[320px] bg-color-background-light text-color-text-black border-2 border-color-border-mid text-sm shadow-[0px_2px_8px_0px_rgba(0,0,0,0.25)] z-10',
      tooltipArrowFirstDiv:
        'absolute top-1/2 left-full border-[12px] border-solid border-t-transparent border-r-transparent border-b-transparent border-color-border-mid -translate-y-1/2',
      tooltipArrowSecondDiv:
        'border-[12px] absolute top-1/2 -translate-y-1/2 -right-[21px] border-solid border-t-transparent border-r-transparent border-b-transparent border-l-color-background-light',
      topTooltipArrowFirstDiv:
        'absolute left-1/2 top-full border-[12px] border-solid border-l-transparent border-r-transparent border-b-transparent border-color-border-mid -translate-x-1/2',
      topTooltipArrowSecondDiv:
        'border-[12px] absolute left-1/2 -translate-x-1/2 -bottom-[21px] border-solid border-l-transparent border-r-transparent border-b-transparent border-t-color-background-light',
      toolTipTitle:
        'font-primary mt-4 font-body-large-bold text-body-large-bold leading-body-large-bold underline',
      topToolTipDescription:
        'font-primary text-body-small-bold font-body-small-bold leading-body-small-bold gap-4 flex flex-col',
      tooltipPhoneNumber: 'text-color-text-brand-2',
      toolTipDescription:
        'pt-[2px] pl-[20px] list-disc font-primary text-body-small-bold font-body-small-bold leading-body-small-bold',
      productActionWrapper: 'flex items-center flex-wrap lg:gap-[55px]',
      addToFavoritesWrapper: 'flex max-w-[148px] font-primary text-left',
      addToCartButton:
        'h-fit bg-color-brand-primary-1-base w-fit min-w-fit text-color-text-white font-primary',
      productQtyWrapper: 'relative w-[130px]',
      productQtyInput:
        'w-[130px] px-[15px] focus:outline-none font-primary text-color-text-black placeholder:text-color-text-black h-[62px] border-[1px] border-color-border-mid',
      productInput:
        'max-w-[40px] outline-none focus:outline-none text-color-text-black placeholder:text-color-text-black font-primary text-heading-desk-large-regular leading-heading-desk-large-regular font-heading-desk-large-regular',
      productQtyButtonWrapper:
        'absolute bottom-[1px] w-[31px] h-[60px] bg-color-background-light right-[1px] flex flex-col',
      productQtyButton: 'w-full h-1/2 text-[17px] font-[700]',
      availabilityWrapper:
        'flex font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      priceText: 'font-primary lg:mr-desk-space-between-tight-vertical',
      priceWrapper: 'flex flex-col',
      storePickUpText: ' font-primary ',
      loginText: 'font-primary',
      pricesWrapper: 'mt-[23px]',
      priceContainer: 'flex flex-col ',
      productInfoWrapper: 'w-full flex',
      heartIconWithTitle:
        'cursor-pointer inline-flex w-4 h-4 [&_svg]:w-4 [&_svg]:h-4 lg:ml-2 [&>svg>*>*]:fill-color-brand-primary-1-base',
      ratingIcon: 'inline-flex w-4 h-4 [&_svg]:w-4 [&_svg]:h-4',
      stockAvailability: '',
      checkAvailabilityText:
        'flex group w-fit justify-start items-center font-primary text-body-large-bold font-body-large-bold leading-body-large-bold text-color-text-brand-1 focus:text-color-brand-tertiary-3-base hover:text-color-brand-tertiary-3-base',
      errorMessage:
        'w-full text-body-large-bold font-primary font-body-large-bold leading-body-large-bold text-color-accent-red',
      radioButton:
        'h-6 w-6 cursor-pointer min-h-6 min-w-6 appearance-none border border-color-border-dark rounded-full outline-none shadow-[inset_22px_0px_6px_6px_color-brand-primary-1-base]',
      relative: 'relative',
      toolbarIconWrapper: 'relative h-fit',
      storeName:
        'underline cursor-pointer ml-mob-space-between-micro-horizontal lg:ml-desk-space-between-micro-horizontal',
      titleWrapper: 'inline-flex w-full',
      addToFavoriteIcon: 'inline-flex [&>svg>*>*]:fill-color-brand-primary-1-base',
      radioButtonFiller:
        'cursor-pointer absolute top-[1px] left-[1px] h-[22px] w-[22px] bg-color-brand-primary-1-base rounded-full',
      reviewWrapper:
        'flex mt-2 lg:mt-0 font-primary gap-mob-space-between-tight-horizontal lg:gap-desk-space-between-tight-horizontal text-body-large-bold font-body-large-bold leading-body-large-bold',
      reviewRatingWrapper: 'flex items-center',
      reviewRating: 'flex items-center',
      reviewNumber: 'text-color-text-dark',
      reviewText:
        'text-color-brand-primary-1-base underline focus:text-color-brand-tertiary-3-base hover:text-color-brand-tertiary-3-base',
      inputWrapper:
        'text-color-text-black placeholder:text-color-text-black font-primary text-heading-desk-medium-regular leading-heading-desk-medium-regular font-heading-desk-medium-regular absolute top-1/2 left-[15px] -translate-y-1/2 flex gap-[17px] items-center',
      tooltipText: '',
    },
    variants: {
      size: {
        desktop: {
          title: 'block',
          tooltipText: 'gap-desk-margin-micro-bottom',
          reviewRatingWrapper: 'gap-desk-space-between-micro-horizontal',
          reviewWrapper: 'mt-0 gap-desk-space-between-tight-horizontal',
          pricesWrapper: 'flex justify-between flex-wrap',
          heartIconWithTitle: 'mt-0 ',
          base: 'flex py-desk-padding-tight-y gap-desk-space-between-base-horizontal',
          productDetailsWrapper: 'gap-desk-margin-base-bottom',
          deliveryOptionsWrapper: 'flex-row gap-desk-space-between-loose-horizontal',
          deliveryOptionWrapper:
            'w-1/2 gap-desk-margin-tight-bottom rounded-desk-global-radius-small px-desk-padding-micro-x py-desk-padding-micro-y',
          availabilityWrapper: 'mt-0',
          productOptionsWrapperForPickup:
            'mt-0 mb-desk-margin-tight-bottom font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',

          productOptionsWrapper:
            'mt-0 mb-desk-margin-tight-bottom font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',
          productOption: 'gap-desk-margin-micro-bottom w-1/2',
          productOptionDropdown:
            'rounded-desk-global-radius-small py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
          pickUpOptionLabel: 'ml-desk-space-between-tight-horizontal',
          changeStoreText: 'gap-desk-space-between-micro-horizontal',
          autoShipOptionWrapper: 'max-w-[270px] w-1/2 rounded-desk-global-radius-small',
          autoShipWrapper:
            'gap-desk-margin-tight-bottom px-desk-padding-micro-x py-desk-global-spacing-spacing-3',
          autoShipText:
            'gap-desk-space-between-tight-horizontal ml-desk-space-between-tight-horizontal',
          autoShipLabel: 'px-desk-global-spacing-spacing-3 py-desk-padding-micro-y',
          tooltipWrapper: 'rounded-desk-global-radius-medium',
          topTooltipWrapper: 'rounded-desk-global-radius-medium',
          productActionWrapper: 'flex-row pt-desk-margin-base-top',
          addToFavoritesWrapper:
            'gap-desk-space-between-tight-horizontal text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          addToCartButton:
            'w-fit px-desk-component-button-full-padding-x rounded-desk-global-radius-small py-desk-component-button-full-padding-y text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          productQtyInput:
            'text-heading-desk-medium-regular leading-heading-desk-medium-regular font-heading-desk-medium-regular rounded-desk-global-radius-small',
          productQtyButtonWrapper:
            'rounded-br-desk-global-radius-small rounded-tr-desk-global-radius-small',
          priceText:
            'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          priceWrapper: 'gap-desk-space-between-micro-vertical',
          storePickUpText:
            'text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
          loginText:
            'text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
          priceContainer: 'gap-desk-space-between-micro-vertical',
          productInfoWrapper: 'flex-row gap-desk-space-between-loose-horizontal',
          stockAvailability: 'ml-desk-space-between-micro-horizontal',
          checkAvailabilityText: 'gap-desk-space-between-micro-horizontal',
        },
        mobile: {
          tooltipText: 'gap-mob-margin-micro-bottom',
          title: 'flex-col',
          heartIconWithTitle: 'mt-[3px]',
          reviewRatingWrapper: 'gap-mob-space-between-micro-horizontal',
          reviewWrapper: 'mt-2 gap-mob-space-between-tight-horizontal',
          availabilityWrapper: 'mt-[23px]',
          productDetailsWrapper: 'gap-mob-space-between-loose-vertical',
          base: 'py-mob-padding-tight-y gap-mob-space-between-base-horizontal',
          deliveryOptionsWrapper: 'flex-col gap-mob-space-between-loose-vertical',
          deliveryOptionWrapper:
            'w-full gap-mob-margin-tight-bottom rounded-mob-global-radius-small px-mob-padding-micro-x py-mob-padding-micro-y',
          productOptionsWrapperForPickup:
            'mb-mob-margin-tight-bottom mt-mob-margin-loose-top font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',
          productOptionsWrapper:
            'mb-mob-margin-tight-bottom mt-mob-margin-loose-top font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',
          productOption: 'gap-mob-margin-micro-bottom',
          productOptionDropdown:
            'rounded-mob-global-radius-small py-mob-global-spacing-spacing-3 px-mob-global-spacing-spacing-4',
          pickUpOptionLabel: 'ml-mob-space-between-tight-horizontal',
          changeStoreText: 'gap-mob-space-between-micro-horizontal',
          autoShipOptionWrapper: 'rounded-mob-global-radius-small',
          autoShipWrapper: 'gap-3 px-mob-padding-micro-x py-mob-global-spacing-spacing-3',
          autoShipText:
            'gap-mob-space-between-tight-horizontal ml-mob-space-between-tight-horizontal',
          autoShipLabel: 'px-mob-global-spacing-spacing-3 py-mob-padding-micro-y',
          tooltipWrapper: 'rounded-mob-global-radius-medium',
          topTooltipWrapper: 'rounded-mob-global-radius-medium',
          productActionWrapper: 'flex-col pt-[22px] gap-mob-space-between-loose-vertical',
          addToFavoritesWrapper:
            'gap-mob-space-between-tight-horizontal text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          addToCartButton:
            'w-full px-mob-component-button-full-padding-x rounded-mob-global-radius-small py-mob-component-button-full-padding-y text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          productQtyInput:
            'text-heading-mob-medium-regular leading-heading-mob-medium-regular font-heading-mob-medium-regular rounded-mob-global-radius-small',
          productQtyButtonWrapper:
            'rounded-br-mob-global-radius-small rounded-tr-mob-global-radius-small',
          priceText:
            'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold mr-mob-space-between-tight-vertical',
          priceWrapper: 'gap-mob-space-between-micro-vertical',
          storePickUpText:
            'text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
          loginText:
            'text-heading-desk-medium-regular font-heading-desk-medium-regular leading-heading-desk-medium-regular',
          priceContainer: 'gap-mob-space-between-micro-vertical',
          productInfoWrapper: 'flex-col gap-mob-space-between-base-vertical',
          stockAvailability: 'ml-mob-space-between-micro-horizontal',
          checkAvailabilityText: 'gap-mob-space-between-micro-horizontal',
        },
      },
      priceText: {
        hasImapPrice: {
          priceText: 'underline cursor-pointer',
        },
        hasMemberPrice: {
          priceText: 'text-pdp-red',
        },
        hasListPrice: {
          priceText: '',
        },
        isListPriceWithMemberPrice: {
          priceText: 'text-color-scale-5-mid line-through',
        },
      },
      isError: {
        true: {
          addToCartButton: 'bg-color-brand-primary-1-disabled cursor-not-allowed',
        },
        false: {
          addToCartButton:
            'hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
        },
      },
      availability: {
        InStock: { stockAvailability: 'text-color-global-in-stock' },
        OutOfStock: { stockAvailability: 'text-color-global-outof-stock' },
        LowStock: { stockAvailability: 'text-color-global-low-stock' },
      },
      isPickUpSelected: {
        true: {
          deliveryOptionWrapper: 'border-color-border-brand',
          autoShipOptionWrapper: 'border-color-border-brand ',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
type InitialState = 'OutOfStock' | 'LowStock' | 'InStock';
interface FacetValue {
  Value: string;
  Count: number;
}

interface Facet {
  Name: string;
  XpPath: string;
  Values: FacetValue[];
  xp: { [key: string]: string };
}

interface Product {
  [key: string]: string[];
}

interface FilteredProductsData {
  filteredProducts?: Product[];
  filterFacetKeys?: string[];
  facets?: Facet[];
}

//main component
const ProductDetail: React.FC<ProductDetailProps> = ({ props }) => {
  const { getDictionaryValue } = useDictionary();
  const {
    base,
    title,
    learnMoreLink,
    productDetailsWrapper,
    productOptionsWrapper,
    productOptionsWrapperForPickup,
    productOptionText,
    deliveryOptionsWrapper,
    deliveryOptionWrapper,
    productOptions,
    productOption,
    productOptionLabel,
    productOptionDropdown,
    pickUpOptionLabel,
    pickUpOptionText,
    changeStoreText,
    changeStoreIcon,
    autoShipOptionWrapper,
    autoShipLabel,
    autoShipWrapper,
    centerDiv,
    autoShipText,
    toolbarIcon,
    tooltipWrapper,
    topTooltipWrapper,
    tooltipContainer,
    tooltipArrowFirstDiv,
    tooltipArrowSecondDiv,
    toolTipTitle,
    toolTipDescription,
    topToolTipDescription,
    tooltipPhoneNumber,
    productActionWrapper,
    addToFavoritesWrapper,
    addToCartButton,
    productQtyWrapper,
    productQtyInput,
    productQtyButtonWrapper,
    productQtyButton,
    availabilityWrapper,
    priceText,
    // storePickUpText,
    priceWrapper,
    // loginText,
    pricesWrapper,
    priceContainer,
    productInfoWrapper,
    heartIconWithTitle,
    checkAvailabilityText,
    stockAvailability,
    errorMessage,
    radioButton,
    relative,
    storeName,
    titleWrapper,
    toolbarIconWrapper,
    addToFavoriteIcon,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
    radioButtonFiller,
    relativeDiv,
    // ratingIcon,
    reviewWrapper,
    // reviewRatingWrapper,
    // reviewRating,
    // reviewNumber,
    // reviewText,
    tooltipText,
    productInput,
    inputWrapper,
    dropdownIcon,
  } = pDPComponentVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const fulfillmentMethod = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.Fulfillment);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const parentId = props?.parentId;
  const productSku = props?.productSku;
  const [qty, setQty] = useState<number | string>(1 || '');
  const [showTooltip, setShowTooltip] = useState(false);
  const [isError, setIsError] = useState<boolean | undefined>(undefined);
  const [productInventory, setProductInventory] = useState(0);
  const [remainingProductInventory, setRemainingProductInventory] = useState<number>();
  const [productAvailability, setProductAvailability] = useState<InitialState>();
  const [showIMapPriceTooltip, setShowIMapPriceTooltip] = useState(false);
  const [inventoryRecordId, setInventoryRecordId] = useState<string>();
  const [showStoreTooltip, setShowStoreTooltip] = useState(false);
  const [isPickUpSelected, setIsPickUpSelected] = useState<boolean>();
  const [isProductAddedWishlist, setIsProductAddedWishlist] = useState<boolean>(false);
  const [filteredProductsData, setFilteredProductsData] = useState<FilteredProductsData>({});
  const [selectedDropdownValues, setSelectedDropdownValues] = useState<{ [key: string]: string }>(
    {}
  );
  const sitecoreContext = useSitecoreContext();

  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const pathArray = router?.asPath?.split('/');
  const productCategory = pathArray?.[4];
  const productParentId = pathArray?.[pathArray?.length - 1]?.split('?')?.[0];
  const renderSEOMetaData = () => {
    return (
      <Head>
        <title>{props?.productData?.SEOMetadata?.Title?.toString() || 'Page'}</title>
        {props?.productData?.SEOMetadata?.Description && (
          <meta
            name="description"
            content={props?.productData?.SEOMetadata?.Description?.toString()}
          />
        )}
        {props?.productData?.SEOMetadata?.Keywords && (
          <meta name="keywords" content={props?.productData?.SEOMetadata?.Keywords?.toString()} />
        )}
      </Head>
    );
  };

  const { isMobile } = useBreakpoints();
  const { getProductAvailability, addToCart, getProductOptions } = useOcCart();

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const [address, setAddress] = useState<{
    Street1?: string;
  }>({});

  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const ecommerceDisabled = myStoreData?.ecommerceDisabled;
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const wishlistData = useOcSelector((s) => s.wishlist?.wishlist);
  const { lineItems } = useOcCurrentOrder();
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const { currentPage, pageItem, position } = getGTMSessionStorage();
  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );
  const autoshipMessage = useAutoshipMessage({
    //TODO: will refactor this later once we will get data from middleware call
    product: { ...props?.productData, xp: props?.productData?.Xp },
    autoshipMarketingText: 'AutoshipMarketingPDP',
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const newMethod = localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod;
      setIsPickUpSelected(newMethod === FulfillmentType.DFS);
    }
  }, [fulfillmentMethod, myStoreData?.storeId, componentContextData?.isDFSMethodChanged]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (currentOrder?.lineItems && currentOrder?.lineItems?.length > 0) {
        setAddress(currentOrder?.lineItems?.[0]?.ShippingAddress || {});
      } else if (localStorage.getItem('selected_delivery_address')) {
        setAddress(JSON.parse(localStorage.getItem('selected_delivery_address') || ''));
      } else {
        setAddress({});
      }
    }
  }, [currentOrder?.lineItems, myStoreData?.storeId, componentContextData?.isDFSMethodChanged]);

  const dataObj = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    '@id': `${props?.domainUrl}${getProductDetailPath({
      ...props?.productData,
      xp: props?.productData?.Xp,
      ParentID: props?.parentId,
    })}`,
    name: props?.productData?.Name,
    image: getFirstProductImage(props?.productData?.Xp?.Images)?.Url,
    description: props?.productData?.Xp?.Bullets?.[0],
    sku: props?.productSku,
    brand: props?.productData?.Xp?.Brand,
  };

  const addToFavoriteClick = (product: ProductSearchResultModelWithVariants) => {
    const productPrice = product && getProductPriceFromSearch(product);
    if (props?.productData?.ID) {
      if (isAnonymous) {
        sendProductsPromotion({
          eventName: GTM_EVENT?.addToWishlist,
          data: product as ProductSearchResultModelWithVariants,
          totalCount: productPrice?.listPrice,
          currency: true,
          currentPage: currentPage,
          pageItem: pageItem,
          storeId: myStoreData?.storeId,
          position: Number(position),
          click_from: GTMLabels?.PDP,
          isRatingShow: true,
        });
        router.push(
          `${urlConfig?.loginWithReturnUrl}${router.asPath}&favoritesku=${props?.productData?.ID}`
        );
      } else {
        dispatch(
          updateProductInWishlist({
            product: product,
            index: Number(position),
            storeId: myStoreData?.storeId,
            clickFrom: GTMLabels?.PDP,
            currentPage: currentPage,
            pageItem: pageItem,
          })
        );
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchProductAvailability = async (productId: any, storeId: any, lowStockSetting: any) => {
    const data = (await getProductAvailability(productId, storeId, lowStockSetting)) as {
      availability?: InitialState;
      productInventory?: number;
      inventoryRecordId?: string;
      remainingInventory?: number;
    };
    return data;
  };

  const getProductData = async () => {
    if (myStoreData?.storeId && props?.productData?.ID) {
      if (props?.parentId) {
        const productOptionData = await getProductOptions(props?.parentId, myStoreData?.storeId);
        if (productOptionData) {
          const selectedDropdownValue: { [key: string]: string } = {};
          productOptionData?.filterFacetKeys?.forEach((key: string) => {
            if (key === retailUnitMeasureFieldName) {
              selectedDropdownValue[key] =
                props?.productData?.Xp?.RetailUnit +
                ' ' +
                props?.productData?.Xp?.RetailMeasure?.toUpperCase();
            } else if (props?.productData?.Xp?.[key]) {
              const value = Array.isArray(props?.productData?.Xp?.[key])
                ? props?.productData?.Xp?.[key]?.join('; ')
                : props?.productData?.Xp?.[key]?.toString();
              selectedDropdownValue[key] = value;
            }
          });
          setSelectedDropdownValues(selectedDropdownValue);
          setFilteredProductsData(productOptionData);
        }
      }
      if (currentOrder?.initialized === true) {
        const data = await fetchProductAvailability(
          props?.productData?.ID,
          myStoreData?.storeId,
          myStoreData?.deliveryFee?.lowStock
        );
        if (data) {
          if (data?.availability) {
            if (data?.availability == 'OutOfStock') {
              setIsError(true);
            } else {
              setIsError(false);
            }
            setProductAvailability(data?.availability);
          }
          if (data?.inventoryRecordId) {
            setInventoryRecordId(data?.inventoryRecordId);
          }
          if (data?.productInventory) {
            setProductInventory(data?.productInventory);
          } else {
            setIsError(true);
            setProductAvailability('OutOfStock');
            setProductInventory(0);
          }
          if (data?.remainingInventory !== null) {
            setRemainingProductInventory(data?.remainingInventory);
          }
        } else {
          setIsError(true);
          setProductAvailability('OutOfStock');
          setProductInventory(0);
        }
      }
    }
  };
  useEffect(() => {
    if (typeof parentId === 'string' && typeof productSku === 'string') {
      const getData = async () => {
        try {
          // Extract the product ID
          const productId = props.productData?.ID;

          // Make a second API call to check product association
          const isNotAssociated = await checkProductAssociation(
            productId,
            myStoreData?.storeId,
            sitecoreContext?.sitecoreContext?.site
          );

          // Redirect to homepage if the product is not associated
          if (isNotAssociated) {
            router.push('/');
          } else {
            setIsError(false);
            setQty(1);
            getProductData();
            //Triggering PDP tracking event for Search
            if (props?.productData?.ID) {
              trackEntityPageViewEvent('product', {
                items: [
                  {
                    id: props.productData?.ID,
                    attributes: {
                      brand: props?.productData?.Xp?.Brand ?? '',
                    },
                  },
                ],
                actionSubtype: 'conversion',
              });
            }
          }
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError('An unknown error occurred');
          }
        } finally {
          setLoading(false);
        }
      };
      if (parentId && productSku && myStoreData?.storeId && props.productData?.ID) {
        getData();
      }
    }
  }, [myStoreData?.storeId]);

  useEffect(() => {
    if (parentId && productSku && myStoreData?.storeId && props.productData?.ID) {
      getProductData();
    }
  }, [lineItems]);

  useEffect(() => {
    if (currentOrder?.initialized === true) {
      getProductData();
    }
  }, [currentOrder?.initialized]);

  useEffect(() => {
    if (props?.productData?.ID && wishlistData) {
      setIsProductAddedWishlist(isProductInWishlist(wishlistData, props?.productData?.ID));
    }
  }, [wishlistData]);

  // Sending GTM promotion data
  useEffect(() => {
    const checkGtmLoad = () => {
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.viewItem)
          ?.length > 0;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any)?.google_tag_manager?.dataLayer?.gtmLoad && !isEventFired) {
        props?.productData &&
          sendProductsPromotion({
            eventName: GTM_EVENT?.viewItem,
            data: props?.productData as ProductSearchResultModelWithVariants,
            currentPage: currentPage,
            pageItem: pageItem,
            parentId: productParentId,
            position: position ?? 0,
            category: productCategory,
            affiliation: myStoreData?.storeId,
            isRatingShow: true,
          });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };

    checkGtmLoad();
  }, [props?.productData]);

  //----------------------
  const updateQuantity = (newQty: number) => {
    const currentLineItem = lineItems?.find((item) => item.ProductID === props?.productData?.ID);
    const currentLineItemQty = currentLineItem?.Quantity ?? 0;

    if (newQty + currentLineItemQty <= productInventory && newQty > 0) {
      setQty(newQty);
      setIsError(false);
    } else {
      setQty(newQty > 0 ? newQty : 1);
      setIsError(newQty + currentLineItemQty > productInventory);
    }
  };

  const priceForPDP = useProductPriceForUI(props.productData?.ID ?? '');

  const renderConditionalPrice = () => (
    <>
      {!priceForPDP?.hasListandMemberPrice ? (
        <div className={priceWrapper()}>
          <div>
            <span
              className={priceText({
                priceText: `${
                  priceForPDP?.hasImapPrice || priceForPDP?.showViewPriceInCart
                    ? 'isListPriceWithMemberPrice'
                    : 'hasListPrice'
                }`,
              })}
            >
              {getDictionaryValue('CurrencySymbol')}
              {priceForPDP?.hasImapPrice
                ? priceForPDP?.imapPrice
                : priceForPDP?.hasListPrice || priceForPDP?.showViewPriceInCart
                ? priceForPDP?.listPrice
                : ''}
            </span>

            <span className={priceText()}>{getDictionaryValue('ListPrice')}</span>
            {(priceForPDP?.hasImapPrice ||
              (priceForPDP?.listPrice != null &&
                priceForPDP?.imapPrice != null &&
                priceForPDP?.memberPrice != null &&
                priceForPDP.listPrice > priceForPDP.imapPrice &&
                priceForPDP.imapPrice > priceForPDP.memberPrice)) && (
              <div className="flex flex-row">
                <span className={priceText({ priceText: 'hasMemberPrice' })}>
                  {getDictionaryValue('ViewPriceInCartPDP')}
                </span>
                <span
                  className={relative()}
                  onMouseEnter={() => {
                    setShowIMapPriceTooltip(true);
                  }}
                  onMouseLeave={() => {
                    setShowIMapPriceTooltip(false);
                  }}
                >
                  <span className={priceText({ priceText: 'hasImapPrice' })}>
                    {getDictionaryValue('WhyWeDoThisPDP')}
                  </span>
                  {showIMapPriceTooltip && (
                    <div className={topTooltipWrapper()}>
                      <div className={tooltipContainer()}>
                        <div className={topTooltipArrowFirstDiv()}></div>
                        <div className={topTooltipArrowSecondDiv()}></div>
                        <p className={topToolTipDescription()}>
                          {getDictionaryValue('WhyWeDoThisTooltipPDP')}
                        </p>
                      </div>
                    </div>
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      ) : (
        // This will render if hasListandMemberPrice is true (Determines if listPrice price is available and greater than PPC and PPC is greater then IMAP prices)
        <div className={priceWrapper()}>
          <p>
            <span className={priceText({ priceText: 'isListPriceWithMemberPrice' })}>
              {getDictionaryValue('CurrencySymbol')}
              {priceForPDP?.listPrice}
            </span>
            <span className={priceText()}>{getDictionaryValue('ListPrice')}</span>
          </p>
          <p>
            <span className={priceText({ priceText: 'hasMemberPrice' })}>
              {getDictionaryValue('CurrencySymbol')}
              {priceForPDP?.memberPrice}
            </span>
            <span className={priceText()}>{getDictionaryValue('MemberPricePDP')}</span>
          </p>
        </div>
      )}
    </>
  );
  const renderPrices = () => {
    return (
      <div className={pricesWrapper()}>
        <div className={priceContainer()}>{renderConditionalPrice()}</div>
        {getDictionaryValue('WriteReviewPDP') && (
          <div className={reviewWrapper()}>
            {props?.productSku && <BazarVoiceReviews bvProductId={props?.productSku} />}
          </div>
        )}
      </div>
    );
  };
  const renderProductInfo = () => (
    <>
      <div>
        <div className={productInfoWrapper()}>
          <div className={titleWrapper()}>
            <div className={title()}>
              <span>{props?.productData?.Name}</span>
              <IconHelper
                className={heartIconWithTitle()}
                onClick={() => {
                  props?.productData && addToFavoriteClick(props?.productData);
                }}
                icon={isProductAddedWishlist ? 'heart' : 'heart-stroke'}
              />
            </div>
          </div>
          <LinkHelper
            className={learnMoreLink()}
            onClick={(e) => {
              e.preventDefault();
              const productDetailsSection =
                document.getElementsByClassName('product-details-section')?.[0];
              productDetailsSection && productDetailsSection.scrollIntoView({ behavior: 'smooth' });
            }}
            field={{
              value: { text: getDictionaryValue('LearnMorePDP'), href: '/' },
            }}
          />
        </div>
        {renderPrices()}
      </div>
      <div className={availabilityWrapper()}>
        <p>{getDictionaryValue('AvailabilityPDP')}</p>
        {/* <p className={stockAvailability({ availability: productAvailability || 'OutOfStock' })}>
          {getDictionaryValue(productAvailability || 'loading')}
        </p> */}
        <p className={stockAvailability({ availability: productAvailability || 'OutOfStock' })}>
          {productAvailability ? (
            getDictionaryValue(productAvailability || 'OutOfStock')
          ) : (
            <Loader className="ml-[5px]" />
          )}
        </p>
      </div>
    </>
  );
  const onBOPISClick = async () => {
    if (currentOrder?.order && currentOrder?.order?.ID) {
      const request: Order = { xp: { Fulfillment: FulfillmentType.BOPIS } };
      await dispatch(patchOrder(request));
    } else {
      localStorage.setItem('selected_fulfillment_Method', FulfillmentType.BOPIS);
      setIsPickUpSelected(false);
    }
  };
  const onDFSClick = async () => {
    if (!address?.Street1) {
      setcomponentContextData({
        ...componentContextData,
        showDeliveryAvailabilityPopup: true,
      });
    } else {
      if (currentOrder?.order && currentOrder?.order?.ID) {
        const request: Order = { xp: { Fulfillment: FulfillmentType.DFS } };
        await dispatch(patchOrder(request));
      } else {
        localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
        setIsPickUpSelected(true);
      }
    }
  };
  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, facetData: Facet) => {
    try {
      setSelectedDropdownValues({
        ...selectedDropdownValues,
        [facetData.Name]: e.target?.value,
      });

      const foundObject = filteredProductsData?.filteredProducts?.find((obj: Product) => {
        return (
          filteredProductsData?.filterFacetKeys &&
          filteredProductsData?.filterFacetKeys.every((key) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const productValue: string = Array.isArray(obj[key] as any)
              ? obj[key]?.join('; ')
              : obj[key]?.toString();
            if (productValue) {
              if (key?.toLowerCase() === e?.target?.id?.toLowerCase()) {
                return productValue?.toLowerCase() === e.target?.value?.toLowerCase();
              }
              if (key === retailUnitMeasureFieldName) {
                return (
                  productValue?.toLowerCase() ===
                  props?.productData?.Xp?.RetailUnit +
                    ' ' +
                    props?.productData?.Xp?.RetailMeasure?.toLowerCase()
                );
              }
              return (
                productValue?.toLowerCase() ===
                props?.productData?.Xp?.[key]?.join('; ')?.toLowerCase()
              );
            } else {
              return false;
            }
          })
        );
      });
      if (foundObject?.sku) {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('sku', foundObject?.sku?.toString());
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.location.href = newUrl;
      }
    } catch (e) {
      console.error('Error', e);
    }
  };
  if (loading) {
    return (
      <div className={'items-center flex-col flex py-[50px]'}>
        {renderProductSchema()}
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!props?.parentId || (props?.parentId && props?.productData?.error)) return <></>;
  function renderProductSchema() {
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(dataObj) }}
      />
    );
  }
  const imageData = getSortedProductImages(props?.productData?.Xp?.Images);
  return (
    <>
      {renderProductSchema()}
      {renderSEOMetaData()}
      <div className={base()}>
        {isMobile && renderProductInfo()}
        {Array.isArray(props?.productData?.Xp?.Images) ? (
          <ProductDetailSliderComponent
            productName={props?.productData?.Name}
            USMade={props?.productData?.Xp?.USMade}
            saleLabel={props?.productData?.Xp?.OnSale ? getDictionaryValue('Sale') : undefined}
            offerLabel={props?.productData?.Xp?.PromoTag}
            dataareaid={myStoreData?.dataareaid}
            priceGroupArray={myStoreData?.priceGroupArray}
            RetailUnit={props?.productData?.Xp?.RetailUnit}
            RetailMeasure={props?.productData?.Xp?.RetailMeasure}
            sliderData={
              imageData?.length && imageData?.length > 0
                ? imageData
                : ([
                    {
                      Url: dummyProductImage?.jsonValue?.value?.src,
                      Name: dummyProductImage?.jsonValue?.value?.alt,
                      Position: 1,
                    },
                  ] as SlideProps[])
            }
            video={props?.productData?.Xp?.Video}
          />
        ) : (
          <></>
        )}
        <div className={productDetailsWrapper()}>
          {!isMobile && renderProductInfo()}
          {filteredProductsData?.facets?.length && filteredProductsData?.facets?.length > 0 ? (
            <div>
              <p className={productOptionsWrapper()}>
                1.
                <span className={productOptionText()}>
                  {getDictionaryValue('SelectYourOptionsPDP')}
                </span>
              </p>
              <div className={productOptions()}>
                {filteredProductsData?.facets?.length &&
                filteredProductsData?.facets?.length > 0 ? (
                  <>
                    {filteredProductsData?.facets?.map((facetData: Facet, index: number) => (
                      <>
                        {facetData?.Values?.length > 0 ? (
                          <div className={productOption()} key={index}>
                            <p className={productOptionLabel()}>
                              {getDictionaryValue(facetData?.Name)}
                            </p>
                            <div className={relative()}>
                              <select
                                aria-label={`product variations ${index}`}
                                name={facetData?.Name}
                                id={facetData?.Name}
                                className={productOptionDropdown()}
                                value={
                                  selectedDropdownValues?.[facetData?.Name]
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                  selectedDropdownValues?.[facetData?.Name]?.slice(1)
                                }
                                onChange={(e) => onSelectChange(e, facetData)}
                              >
                                {facetData?.Values?.map((value, key) => (
                                  <option
                                    value={
                                      value?.Value?.charAt(0)?.toUpperCase() +
                                      value?.Value?.slice(1)
                                    }
                                    id={facetData?.Name}
                                    key={key}
                                  >
                                    {value?.Value?.charAt(0)?.toUpperCase() +
                                      value?.Value?.slice(1)}
                                  </option>
                                ))}
                              </select>
                              <IconHelper className={dropdownIcon()} icon="dropdown" />
                            </div>
                          </div>
                        ) : null}
                      </>
                    ))}
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          <div>
            <p className={productOptionsWrapperForPickup()}>
              {filteredProductsData?.facets?.length && filteredProductsData?.facets?.length > 0
                ? '2.'
                : ''}
              <span
                className={
                  filteredProductsData?.facets?.length && filteredProductsData?.facets?.length > 0
                    ? productOptionText()
                    : ''
                }
              >
                {getDictionaryValue('SelectPickupOrDeliveryPDP')}
              </span>
            </p>
            <div className={deliveryOptionsWrapper()}>
              {props?.productData?.Xp?.Curbside && (
                <div
                  className={deliveryOptionWrapper({ isPickUpSelected: !isPickUpSelected })}
                  onClick={onBOPISClick}
                >
                  <div className={relativeDiv()}>
                    <div className={centerDiv()}>
                      <input
                        aria-label="delivery_option_bopis"
                        type="radio"
                        className={radioButton()}
                        onClick={onBOPISClick}
                        name="delivery_option"
                        checked={!isPickUpSelected}
                        value={getDictionaryValue('FreeCurbsidePickupPDP')}
                      />
                      {!isPickUpSelected && <div className={radioButtonFiller()}></div>}
                    </div>
                    <label className={pickUpOptionLabel()}>
                      {getDictionaryValue('FreeCurbsidePickupPDP')}
                    </label>
                  </div>
                  <div className={pickUpOptionText()}>
                    {getDictionaryValue('PickupAtYourStorePDP')}
                    {myStoreData?.storeName ? (
                      <span className={relative()}>
                        <span
                          className={storeName()}
                          onMouseEnter={() => {
                            setShowStoreTooltip(true);
                          }}
                          onMouseLeave={() => {
                            setShowStoreTooltip(false);
                          }}
                        >
                          {myStoreData?.storeName?.toString()}
                        </span>
                        {showStoreTooltip && (
                          <div className={topTooltipWrapper()}>
                            <div className={tooltipContainer()}>
                              <div className={topTooltipArrowFirstDiv()}></div>
                              <div className={topTooltipArrowSecondDiv()}></div>
                              <div className={topToolTipDescription()}>
                                <p>{getDictionaryValue('OrderReadyMessagePDP')}</p>
                                <div className={tooltipText()}>
                                  <p>
                                    <span>{`${myStoreData?.street1}, ${myStoreData?.city}, ${myStoreData?.state}`}</span>
                                  </p>
                                  {myStoreData?.phone ? (
                                    <p className={tooltipPhoneNumber()}>
                                      {myStoreData?.phone as React.ReactNode}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <LinkHelper
                    className={changeStoreText()}
                    onClick={(e) => {
                      e.preventDefault();
                      setcomponentContextData({
                        ...componentContextData,
                        showChangeStorePopup: true,
                        productId: props.productData?.ID,
                      });
                    }}
                    field={{ value: { href: '/', text: getDictionaryValue('ChangeStorePDP') } }}
                  >
                    <>
                      <Text field={{ value: getDictionaryValue('ChangeStorePDP') }} tag="p" />
                      <IconHelper className={changeStoreIcon()} icon={'chevron-right'} />
                    </>
                  </LinkHelper>
                </div>
              )}
              {myStoreData?.disableDelivery !== 1 &&
                (props?.productData?.Xp?.Delivery || props?.productData?.Xp?.Autoship) && (
                  <div
                    className={autoShipOptionWrapper({ isPickUpSelected: isPickUpSelected })}
                    onClick={onDFSClick}
                  >
                    {autoshipMessage && <p className={autoShipLabel()}>{autoshipMessage}</p>}
                    <div className={autoShipWrapper()}>
                      <div className={relativeDiv()}>
                        <div className={centerDiv()}>
                          <input
                            aria-label="delivery_option_dfs"
                            type="radio"
                            className={radioButton()}
                            checked={isPickUpSelected}
                            onClick={onDFSClick}
                            name="delivery_option"
                            value={getDictionaryValue('DeliveryOrAutoshipPDP')}
                          />
                          {isPickUpSelected && <div className={radioButtonFiller()}></div>}
                        </div>
                        <label className={autoShipText()}>
                          {getDictionaryValue('DeliveryOrAutoshipPDP')}
                          <div className={toolbarIconWrapper()}>
                            <IconHelper
                              className={toolbarIcon()}
                              icon={'info'}
                              onMouseEnter={() => {
                                setShowTooltip(true);
                              }}
                              onMouseLeave={() => {
                                setShowTooltip(false);
                              }}
                            />
                            {showTooltip && (
                              <div className={tooltipWrapper()}>
                                <div className={tooltipContainer()}>
                                  <div className={tooltipArrowFirstDiv()}></div>
                                  <div className={tooltipArrowSecondDiv()}></div>
                                  <ul className={toolTipDescription()}>
                                    {getDictionaryValue('DeliveryLearnMoreButtonToolipOrderPDP')
                                      ?.split('|')
                                      ?.map((value: string, index: number) => (
                                        <li key={index}>{value}</li>
                                      ))}
                                  </ul>
                                  <p className={toolTipTitle()}>
                                    {getDictionaryValue('AutoshipEnrollmentPDP')}
                                  </p>
                                  <ul className={toolTipDescription()}>
                                    {getDictionaryValue(
                                      'DeliveryLearnMoreButtonToolipEnrollmentPDP'
                                    )
                                      ?.split('|')
                                      ?.map((value: string, index: number) => (
                                        <li key={index}>{value}</li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        </label>
                      </div>
                      {address?.Street1 && <p className={pickUpOptionText()}>{address?.Street1}</p>}
                      <LinkHelper
                        className={checkAvailabilityText()}
                        onClick={(e) => {
                          e.preventDefault();
                          setcomponentContextData({
                            ...componentContextData,
                            showDeliveryAvailabilityPopup: true,
                          });
                        }}
                        field={{
                          value: {
                            href: '/',
                            text: address?.Street1
                              ? getDictionaryValue('ChangeStorePDP')
                              : getDictionaryValue('CheckAvailabilityPDP'),
                          },
                        }}
                      >
                        <>
                          <Text
                            field={{
                              value: address?.Street1
                                ? getDictionaryValue('ChangeAddressPDP')
                                : getDictionaryValue('CheckAvailabilityPDP'),
                            }}
                            tag="p"
                          />
                          <IconHelper className={changeStoreIcon()} icon={'chevron-right'} />
                        </>
                      </LinkHelper>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div>
            {isError && (
              <>
                <p className={errorMessage()}>
                  {!productInventory && productAvailability === 'OutOfStock'
                    ? getDictionaryValue('NoProductUnitsAvailablePDP')
                    : productInventory === 1
                    ? getDictionaryValue('OneProductUnitsAvailablePDP')
                    : getDictionaryValue('ProductUnitsAvailablePDP')?.replace(
                        '{productUnit}',
                        remainingProductInventory && remainingProductInventory > 0
                          ? remainingProductInventory?.toString()
                          : parseInt(productInventory?.toString()) < 0
                          ? '0'
                          : productInventory?.toString() ?? '0'
                      )}
                </p>
              </>
            )}
            <div className={productActionWrapper()}>
              <div className={productQtyWrapper()}>
                <div className={relative()}>
                  <div className={productQtyInput()}></div>
                  <div className={inputWrapper()}>
                    <p>{getDictionaryValue('QuantityPDP')}</p>
                    <input
                      aria-label="quantity"
                      type="text"
                      className={productInput()}
                      //as per FSD min will be 1
                      onChange={(e) => {
                        e.preventDefault();
                        const value = parseInt(e.target?.value, 10);
                        if (!Number.isNaN(value)) {
                          updateQuantity(value);
                        } else {
                          setQty('');
                        }
                      }}
                      onBlur={(e) => {
                        const value = parseInt(e.target?.value, 10);
                        if (Number.isNaN(value) || value <= 0) {
                          setQty(1);
                        } else {
                          updateQuantity(value);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // Reset to 1 if empty or 0 on Enter key press

                          if (qty === 0 || qty === '') {
                            setQty(1);
                            updateQuantity(1);
                          } else {
                            updateQuantity(Number(qty));
                          }
                        }
                      }}
                      //placeholder={`1`}
                      value={`${qty}`}
                    />
                  </div>
                  <div className={productQtyButtonWrapper()}>
                    <button
                      aria-label="add to cart"
                      className={productQtyButton()}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!Number.isNaN(qty)) {
                          updateQuantity(Number(qty) + 1);
                        }
                      }}
                    >
                      +
                    </button>
                    <button
                      aria-label="remove from cart"
                      className={productQtyButton()}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!Number.isNaN(qty) && Number(qty) > 1) {
                          updateQuantity(Number(qty) - 1);
                        }
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
              {ecommerceDisabled === 0 && (
                <>
                  <button
                    aria-label="add to cart"
                    onClick={async () => {
                      if (!isError && props?.productData?.ID) {
                        try {
                          await addToCart({
                            productId: props?.productData?.ID,
                            quantity: +qty,
                            storeId: myStoreData?.storeId,
                            inventoryItemId: inventoryRecordId,
                          });
                          const availabilityData = await fetchProductAvailability(
                            props?.productData?.ID,
                            myStoreData?.storeId,
                            myStoreData?.deliveryFee?.lowStock
                          );
                          {
                          }
                          if (availabilityData?.availability) {
                            if (availabilityData?.availability == 'OutOfStock') {
                              setIsError(true);
                            } else {
                              setIsError(false);
                            }
                            setProductAvailability(availabilityData?.availability);
                          }
                          if (availabilityData?.inventoryRecordId) {
                            setInventoryRecordId(availabilityData?.inventoryRecordId);
                          }
                          if (availabilityData?.productInventory) {
                            setProductInventory(availabilityData?.productInventory);
                          }
                          if (availabilityData?.remainingInventory) {
                            setRemainingProductInventory(availabilityData?.remainingInventory);
                          } else {
                            setIsError(true);
                          }
                        } catch (_error) {
                          setIsError(true);
                        }
                      }
                      sendProductsPromotion({
                        eventName: GTM_EVENT?.addToCart,
                        data: props?.productData as ProductSearchResultModelWithVariants,
                        currentPage: currentPage,
                        pageItem: pageItem,
                        position: 0,
                        click_from: GTMLabels?.PDP,
                        quantity: qty as number,
                        isRatingShow: true,
                        fulfillment_option: isPickUpSelected ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                      });

                      //Sending Add Cart event to Search
                      trackAddEvent('product', 'cart', {
                        items: [{ entityType: 'product', id: props?.productData?.ID as string }],
                        actionSubtype: 'conversion',
                      });
                    }}
                    className={clsx(
                      addToCartButton({ isError: isError }),
                      productAvailability === 'OutOfStock' && 'opacity-[0.5] pointer-events-none'
                    )}
                  >
                    {getDictionaryValue('AddToCartCTAPDP')}
                  </button>
                </>
              )}

              <button
                className={addToFavoritesWrapper()}
                onClick={() => {
                  props?.productData && addToFavoriteClick(props?.productData);
                }}
                aria-label="add to favorites"
              >
                <IconHelper
                  className={addToFavoriteIcon()}
                  icon={isProductAddedWishlist ? 'heart' : 'heart-stroke'}
                />
                <span>
                  {isProductAddedWishlist
                    ? getDictionaryValue('RemoveFromFavoritesPDP')
                    : getDictionaryValue('AddToFavoritesPDP')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <StoreDetailTab
        bvProductId={props?.productSku}
        bullets={props?.productData?.PCMProduct?.Product?.ProductDetailsBullets}
        ingredientsComposition={props?.productData?.PCMProduct?.Product?.Ingredients}
        guaranteedAnalysisNutrientName={
          props?.productData?.PCMProduct?.Product?.GuaranteedAnalysisNutrientName
        }
        GuaranteedAnalysisPDP={getDictionaryValue('GuaranteedAnalysisPDP')}
      />
    </>
  );
};
//check withDataSourceCheck If it is not then show blank instead of error.
export default ProductDetail;

export interface ProductDetailStaticProps extends ComponentRendering {
  productData: ProductUrlData | null;
}

export async function getStaticProps(
  props: ProductDetailStaticProps,
  layoutData: LayoutServiceData
) {
  try {
    const parentId = props.productData?.parentid; // 1127;
    const productSku = props.productData?.productSku; // 076484182051;
    const sitecore = layoutData?.sitecore;
    const sitecoreContext = sitecore?.context;
    const siteName = sitecoreContext?.site?.name as string;
    const domainUrl = extractDomainUrlFromConfig(siteName);

    if (parentId && productSku) {
      const url = `${domainUrl}/${apiConfig?.productAPI?.getProductAPI
        ?.replace('{parentid}', parentId)
        ?.replace('{productSku}', productSku)
        ?.replace('{siteName}', getSiteName(siteName)?.toUpperCase())}`;
      const data: ProductUrlData & {
        ID: string;
      } = await apiRequest(url, {
        method: 'GET',
        headers: {
          site: siteName,
        },
      });
      if (data && data?.ID) {
        return {
          props: {
            ...props,
            productData: data,
            parentId: parentId,
            productSku: productSku,
            domainUrl: domainUrl,
          },
        };
      } else {
        return {
          props: {
            ...props,
          },
        };
      }
    } else {
      return {
        props: {
          ...props,
        },
      };
    }
  } catch (_err) {
    return {
      props: {
        ...props,
        error: 'data',
      },
    };
  }
}
