import { Dispatch, SetStateAction, createContext } from 'react';

export interface ComponentContextData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  expandedForm?: 'pickup' | 'delivery' | 'billing' | 'tip' | 'payment' | 'frequency' | null;
}

interface ComponentContextProps {
  componentContextData: ComponentContextData;
  setcomponentContextData: Dispatch<SetStateAction<ComponentContextData>>;
  setExpandedForm: (
    form: 'pickup' | 'delivery' | 'billing' | 'tip' | 'payment' | 'frequency' | null
  ) => void;
}

const ComponentContext = createContext<ComponentContextProps>({
  componentContextData: {},
  setcomponentContextData: (_state: ComponentContextData) => {
    _state;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setExpandedForm: () => {},
});

export default ComponentContext;
