import React, { useEffect, useRef, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import IconHelper from 'components/helpers/IconHelper';
import { useBreakpoints } from 'src/utils/breakpoints';
import ReactPlayer from 'react-player';
import { tv } from 'tailwind-variants';
import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { pDPComponentVariants } from 'components/ProductDetail/ProductDetail';
import { getProductPromoTag, getTruncatedProductPromoTag } from 'components/helpers/Constants';
import { PromoTagXp } from 'src/redux/xp';
import { useOcSelector } from 'src/redux/ocStore';
export type PDPCarouselProps = {
  productName?: string;
  sliderData?: SlideProps[];
  USMade?: boolean;
  saleLabel?: string;
  offerLabel?: PromoTagXp[];
  RetailUnit?: string;
  RetailMeasure?: string;
  video?: string;
  dataareaid?: string;
  priceGroupArray?: string[];
};
export type SlideProps = { Position: number; isVideo?: boolean; Url?: string; Name: string };
export const PDPCarouselVariant = tv(
  {
    slots: {
      base: 'w-fit h-fit relative max-w-full',
      sliderWrapper: 'border-2 border-color-border-light relative lg:ml-[148px] overflow-hidden',
      retailUnit: [
        'bg-color-brand-primary-1-base',
        'px-desk-global-spacing-spacing-1',
        'text-body-small-bold',
        'font-body-small-bold',
        'leading-body-small-bold',
        'text-white',
        'flex',
        'items-center',
      ],
      sale: [
        'z-[1]',
        'left-[5%]',
        'lg:left-[27.5%]',
        'top-[6.4%]',
        'absolute',
        'bg-system-red',
        'text-color-text-white',
        'text-body-small-bold',
        'font-body-small-bold',
        'leading-body-small-bold',
        // 'ml-desk-global-spacing-spacing-2',
        'px-desk-global-spacing-spacing-2',
        'py-desk-global-spacing-spacing-1',
      ],
      offerLabel: [
        'absolute',
        'left-[-45px]',
        'bottom-[40px]',
        'lg:left-[-60px]',
        'lg:w-[236px]',
        'z-[1]',
        'w-fit',
        'text-color-text-white',
        'rotate-45',
        'text-heading-mob-medium-regular',
        'font-heading-mob-medium-regular',
        'leading-heading-mob-medium-regular',
        'py-desk-global-spacing-spacing-2',
        'px-desk-padding-tight-x',
        'bg-color-accent-red',
      ],
      flagIcon: ['absolute'],
      tagsWrapper: ['flex', 'relative', 'overflow-hidden'],
      retailWrapper: ['flex', 'absolute', 'bottom-[224px]', 'right-[5px]', 'z-[1]'],
      retaiIcon: ['[&>svg>*]:fill-color-brand-primary-1-base'],
      thumbnailIcon: '[&_svg]:fill-color-scale-7-mid-dark [&_path]:fill-color-scale-7-mid-dark',
      thumbnailButton: 'h-6 left-0 w-full flex justify-center',
      thumbnailWrapper: '!absolute hidden left-0 w-fit h-[calc(100%-56px)] top-0 flex-col',
      carouselVideo:
        'object-cover relative overflow-hidden w-full h-full lg:w-[385px] lg:h-[500px] lg:pt-[25%] lg:pb-[25%]',
      carouselImage: 'h-auto w-auto mx-auto',
      carouselSlider: 'w-full lg:w-[417px]',
      carouselNextArrow:
        'splide__arrow--next slider-arrow slider-next !bg-[transparent] [&_svg]:!h-6 [&_svg]:!w-6 !right-[-7px] !opacity-100 [&_svg]:!fill-color-scale-7-mid-dark',
      carouselPrevArrow:
        'splide__arrow--prev slider-arrow slider-prev !bg-[transparent] [&_svg]:!h-6 [&_svg]:!w-6 !left-[-7px] !opacity-100 [&_svg]:!fill-color-scale-7-mid-dark',
    },
    variants: {
      size: {
        desktop: {
          thumbnailWrapper: 'flex gap-desk-space-between-micro-vertical',
          carouselVideo: 'w-[385px] h-[500px] pt-[25%] pb-[25%]',
          carouselImage: '!max-h-[500px]',
          base: 'my-0',
          carouselSlider: 'p-4 [&_ul]:!w-fit [&_li]:!w-fit [&_li]:!h-fit ',
          flagIcon: ['left-[27.5%]', 'top-2', 'z-[1]'],
        },
        mobile: {
          base: 'my-mob-space-between-base-horizontal',
          thumbnailWrapper: 'gap-mob-space-between-micro-vertical',
          carouselVideo: 'w-full h-full',
          carouselImage: 'max-h-full',
          carouselSlider:
            'py-mob-padding-micro-y [&_li]:!min-h-[508px] [&_li]:flex [&_li]:items-center px-mob-global-spacing-spacing-4 [&_ul]:!h-fit ',
          flagIcon: ['left-[5%]', 'top-[1%]', 'z-[1]'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
const ProductDetailSliderComponent = (props: PDPCarouselProps) => {
  const {
    base,
    retailUnit,
    sale,
    offerLabel,
    carouselNextArrow,
    carouselPrevArrow,
    carouselSlider,
    carouselImage,
    carouselVideo,
    thumbnailWrapper,
    thumbnailButton,
    thumbnailIcon,
    flagIcon,
    retailWrapper,
    tagsWrapper,
    retaiIcon,
    sliderWrapper,
  } = PDPCarouselVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    topTooltipWrapper,
    tooltipContainer,
    topToolTipDescription,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
  } = pDPComponentVariants({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const primarySliderRef = useRef<Splide | null>(null);
  const secondarySliderRef = useRef<Splide | null>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [showOfferTooltip, setShowOfferTooltip] = useState<boolean>();

  useEffect(() => {
    const primarySlider = primarySliderRef.current;
    const secondarySlider = secondarySliderRef.current;
    if (primarySlider && secondarySlider && secondarySlider?.splide) {
      primarySlider.sync(secondarySlider?.splide);
    }
  }, []);

  useEffect(() => {
    primarySliderRef?.current?.splide?.Components.Controller?.setIndex(activeSlideIndex);
    primarySliderRef?.current?.splide?.Components?.Move?.move(
      activeSlideIndex,
      activeSlideIndex,
      0
    );
    secondarySliderRef?.current?.splide?.Components.Controller?.setIndex(activeSlideIndex);
    secondarySliderRef?.current?.splide?.Components?.Move?.move(
      activeSlideIndex,
      activeSlideIndex,
      0
    );
  }, [activeSlideIndex]);
  const getYoutubeVideoId = () => {
    const match = props?.video?.match(
      /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([^&\n?#]+)/
    );
    return match && match[1];
  };

  const { isMobile, isDesktop } = useBreakpoints();
  const promoTag =
    props?.offerLabel &&
    getProductPromoTag(props?.offerLabel, props?.dataareaid, props.priceGroupArray);
  const maxLengthForPromoTag =
    Number(useOcSelector((state) => state?.otherSettingSlice?.data?.maxLengthForPromoTag?.value)) ||
    0;
  const direction = !isMobile ? 'ttb' : undefined;
  return (
    <div className={base()}>
      {props?.USMade && (
        <NextImage
          field={{
            value: {
              src: '/images/badge-flag-1.png',
              alt: 'US Made',
              width: '27',
              height: '18',
            },
          }}
          className={flagIcon()}
        />
      )}

      <div>
        {props?.saleLabel && <span className={sale()}>{props?.saleLabel?.toUpperCase()}</span>}
        <div className={tagsWrapper()}>
          <div className={retailWrapper()}>
            <span className={retailUnit()}>
              {props?.RetailUnit}&nbsp;
              {props?.RetailMeasure}
            </span>
            <IconHelper icon="vector" className={retaiIcon()} />
          </div>

          {showOfferTooltip && (
            <div
              className={topTooltipWrapper({
                className: '!bottom-[30%] lg:!bottom-[39%] left-[5%] translate-x-0 lg:left-[20.5%]',
              })}
            >
              <div className={tooltipContainer()}>
                <div className={topTooltipArrowFirstDiv()}></div>
                <div className={topTooltipArrowSecondDiv()}></div>
                <p className={topToolTipDescription({ className: 'text-center' })}>{promoTag}</p>
              </div>
            </div>
          )}
          <div className={sliderWrapper()}>
            <Splide
              ref={primarySliderRef}
              options={{
                type: 'loop',
                heightRatio: isMobile ? 0.6417 : 0.77,
                autoHeight: true,
                pagination: false,
                arrows: !!(
                  (props?.video && props?.sliderData?.length && props?.sliderData?.length > 0) ||
                  (props?.sliderData?.length && props?.sliderData?.length > 1)
                ),
                classes: {
                  prev: carouselPrevArrow(),
                  next: carouselNextArrow(),
                },
              }}
              onMoved={(_currentIndex, newIndex) => {
                setActiveSlideIndex(newIndex);
              }}
              aria-label="Product Images"
              className={carouselSlider()}
            >
              {props?.sliderData?.map(
                (slider: { isVideo?: boolean; Url?: string; Name?: string }, index: number) => (
                  <SplideSlide key={index}>
                    {!slider?.isVideo ? (
                      <>
                        <img
                          src={slider?.Url}
                          alt={props?.productName}
                          className={carouselImage()}
                        />
                      </>
                    ) : (
                      <div className={carouselVideo()}>
                        <ReactPlayer width="100%" height="100%" url={slider.Url} controls />
                      </div>
                    )}
                  </SplideSlide>
                )
              )}
              {props?.video ? (
                <SplideSlide>
                  <div className={carouselVideo()}>
                    <ReactPlayer width="100%" height="100%" url={props?.video} controls />
                  </div>
                </SplideSlide>
              ) : (
                <></>
              )}
            </Splide>
            {promoTag && (
              <div
                className={offerLabel()}
                onMouseEnter={() => {
                  if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                    setShowOfferTooltip(true);
                  }
                }}
                onMouseLeave={() => {
                  if (maxLengthForPromoTag && promoTag && promoTag?.length > maxLengthForPromoTag) {
                    setShowOfferTooltip(false);
                  }
                }}
              >
                <p className="mx-auto w-fit">
                  {getTruncatedProductPromoTag(promoTag, maxLengthForPromoTag)}
                </p>
              </div>
            )}
          </div>
        </div>
        {isDesktop && props?.sliderData ? (
          <div className={thumbnailWrapper()}>
            {(props?.video && props?.sliderData?.length && props?.sliderData?.length > 3) ||
            (props?.sliderData?.length && props?.sliderData?.length > 4) ? (
              <button
                aria-label="arrow"
                className={thumbnailButton()}
                onClick={() => {
                  if (!(activeSlideIndex === 0)) {
                    setActiveSlideIndex(activeSlideIndex - 1);
                  }
                }}
              >
                <IconHelper icon={'chevron-up'} className={thumbnailIcon()} />
              </button>
            ) : (
              <></>
            )}
            {props?.sliderData ? (
              <Splide
                ref={secondarySliderRef}
                id="secondary-slider"
                options={{
                  gap: '12px',
                  pagination: false,
                  fixedWidth: 124,
                  fixedHeight: 108,
                  direction: direction, // Setting direction to top to bottom
                  heightRatio: 1.1481,
                  perPage: 4,
                  perMove: 1,
                  arrows: false,
                  classes: {
                    prev: '!hidden',
                    next: '!hidden',
                  },
                }}
                onClick={(_slide, e) => {
                  setActiveSlideIndex(e.index);
                }}
                className="splide pdp-thumbnail-slider [&_ul]:min-h-full"
              >
                {props?.sliderData?.map(
                  (slider: { isVideo?: boolean; Url?: string; Name?: string }, index: number) => (
                    <SplideSlide key={index}>
                      <div
                        className={`p-desk-global-spacing-spacing-3 w-[124px] h-[108px] border-2 cursor-pointer flex justify-center ${
                          activeSlideIndex === index
                            ? 'border-color-border-brand'
                            : 'border-color-border-light'
                        }`}
                      >
                        {
                          <img
                            src={slider?.Url}
                            alt={props?.productName}
                            className="w-auto object-cover h-full"
                          />
                        }
                      </div>
                    </SplideSlide>
                  )
                )}
                {props?.video ? (
                  <SplideSlide>
                    <div
                      className={`p-desk-global-spacing-spacing-3 w-[124px] h-[108px] border-2 cursor-pointer flex justify-center ${
                        activeSlideIndex === props?.sliderData?.length
                          ? 'border-color-border-brand'
                          : 'border-color-border-light'
                      }`}
                    >
                      {/* <ReactPlayer width="100%" height="100%" url={props?.video} controls={false} /> */}

                      <img
                        src={`https://img.youtube.com/vi/${getYoutubeVideoId()}/1.jpg`}
                        alt={props?.productName}
                        className="w-auto object-cover h-full"
                      />
                    </div>
                  </SplideSlide>
                ) : (
                  <></>
                )}
              </Splide>
            ) : (
              <></>
            )}
            {(props?.video && props?.sliderData?.length && props?.sliderData?.length > 3) ||
            (props?.sliderData?.length && props?.sliderData?.length > 4) ? (
              <button
                aria-label="arrow"
                className={thumbnailButton()}
                onClick={() => {
                  if (
                    props?.sliderData?.length &&
                    props?.sliderData?.length > activeSlideIndex + 1
                  ) {
                    setActiveSlideIndex(activeSlideIndex + 1);
                  }
                }}
              >
                <IconHelper icon={'chevron-down'} className={thumbnailIcon()} />
              </button>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ProductDetailSliderComponent;
