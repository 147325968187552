import { extractDomainUrlFromConfig } from 'src/helpers/publicURLTrailingSlash';
import { BuyerProductWithXp, PromoTagXp } from 'src/redux/xp';
import { Nullable } from 'src/utils/typeUtils';

// Will contain global required typescript constants.
export const doubleCalloutPrimaryVariantName = 'Pre Footer with CTA';
export type Theme = 'psp' | 'wnw';
export type ThemeUpperCase = 'PSP' | 'WNW';
export type SiteName = 'psp' | 'wagnwash';
export type SiteNameUppercase = 'PSP' | 'WAGNWASH';
export const closedText = 'Closed';
export interface ThemeNameType {
  [key: string]: ThemeUpperCase;
}

export const FulfillmentType = {
  BOPIS: 'BOPIS',
  DFS: 'DFS',
} as const;

export const DiscreteLineItem = {
  TIP: '3036101', //For Tip amount in Cart
  // CORDF: 'CORDF', //Colorado Retail Delivery Fee//We no longer need this. PSP-2731
};
export type backgroundTheme = 'Green' | 'Red' | 'Blue' | 'Purple';

type FulfillmentTypeKeys = keyof typeof FulfillmentType;
export type FulfillmentTypeValue = (typeof FulfillmentType)[FulfillmentTypeKeys];

export enum productAvailability {
  IN = 'InStock',
  LOW = 'LowStock',
  OUT = 'OutOfStock',
}

export const loaderGIF = {
  value: {
    src: '/images/loader.gif',
    alt: 'loader',
    width: '32',
    height: '32',
  },
};

export const errorCode = {
  serverErrorCode: 500,
};

export const currentPath = {
  isCheckout: '/checkout',
  isShoppingCart: '/shopping-cart',
  isLoginCheckout: '/logincheckout',
  isLogin: '/login',
  isSearchPage: '/search',
  isOrderConfirmation: '/checkout/orderconfirmation',
  isShopsPage: '/categories',
  isHome: '/',
};

export const redirectTo = {
  checkout: 'ReturnUrl=/checkout',
};

export const addressType = {
  delivery: 'Delivery',
  billing: 'Billing',
  home: 'Home',
};

export const groomingUpcomingAppointmentStatus = {
  booked: 'booked',
  auto_booked: 'auto_booked',
  confirmed: 'confirmed',
};

export const groomingPastAppointmentStatus = {
  CanceledByConsumer: 'canceled_by_consumer',
  CanceledByMerchant: 'canceled_by_merchant',
  NoShow: 'NoShow',
  NoShowWithFee: 'no_show_with_fee',
  Completed: 'completed',
  CanceledByConsumerWithFee: 'canceled_by_consumer_with_fee',
  CanceledByMerchantWithFee: 'canceled_by_merchant_with_fee',
};

export const Country = 'US';

export const formType = {
  payment: 'payment',
};

export const sites = {
  psp: 'PSP',
  wnw: 'WNW',
};

//GTM Constant ----------
export const GTM_EVENT = {
  user: 'userEvent',
  viewPromotion: 'view_promotion',
  selectPromotion: 'select_promotion',
  viewItemList: 'view_item_list',
  selectItem: 'select_item',
  viewItem: 'view_item',
  viewCart: 'view_cart',
  addToCart: 'add_to_cart',
  removeFromCart: 'remove_from_cart',
  viewShoppingCart: 'view_cart',
  beginCheckout: 'begin_checkout',
  addShippingInfo: 'add_shipping_info',
  addPaymentInfo: 'add_payment_info',
  purchase: 'purchase',
  storeLocator: 'storeLocator',
  shopThisStore: 'shopThisStore',
  storeLocatorError: 'storeLocatorError',
  groomingStoreLocator: 'groomingStoreLocator',
  groomingSelectStore: 'groomingSelectStore',
  selectStore: 'selectStore',
  deliveryAvailError: 'deliveryAvailError',
  addToWishlist: 'add_to_wishlist',
  removeFromWishlist: 'remove_from_wishlist',
  groomingSteps: 'groomingSteps',
  groomingSubmit: 'groomingSubmit',
  groomingCancel: 'groomingCancel',
};
export const currencyType = 'USD';

export const GTMLabels = {
  guest: 'Guest',
  loggedIn: 'LoggedIn',
  featuredItems: 'Featured Items',
  BOPIS: 'Pickup',
  DFS: 'Delivery',
  yes: 'Yes',
  no: 'No',
  notAvailable: 'not available',
  currentPage: 'currentPage',
  pageItem: 'pageItem',
  position: 'position',
  PDP: 'PDP',
  PLP: 'PLP',
  cart: 'Cart',
  myFavorites: 'My Favorites',
  listing: 'Listing',
  fromMiniCart: 'From Mini Cart',
  fromShoppingCart: 'From Shopping Cart',
  fromCartRemove: 'Cart-Remove',
  fromPlus: 'Cart - Plus Sign',
  fromMinus: 'Cart - Minus Sign',
  fromRemove: 'Cart - Remove',
  fromMiniCartMinus: 'My Cart Popup - Minus Sign',
  fromMiniCartRemove: 'My Cart Popup - Remove',
  fromMiniCartPlusSign: 'My Cart Popup - Plus Sign',
  fromBrands: 'Brands',
  fromMyFavorites: 'My Favorites',
  firstTime: '1-time',
  pickup: 'pick-up',
  curbSide: 'curbside',
  autoShip: 'autoship',
  delivery: 'delivery',
  storeError: 'Sorry, no stores found matching your search',
  groomingStoreError:
    'We do not offer the ability to schedule online grooming appointments at stores is reached area',
  deliveryStoreError: 'At this time, we do not deliver to the address provided',
  deliveryAvailabilityError: 'We are unable to locate the exact address you entered for delivery',
  groomingStep1StepName: 'Set Grooming Salon',
  groomingStep2StepName: 'Select Dog',
  groomingStep3StepName: 'Select Services',
  groomingStep4StepName: 'Select Date, Time, and Grooming Professional',
  groomingSubmit: 'Submit',
  groomingProfessionalAndDateRange: 'Select Date, Time, and Grooming Professional​',
  groomingSearchByProfessionalDateRange: 'Grooming Professional and Date Range',
  groomingNextAvailableAppointment: 'Next Available Appointment',
  searchPage: 'Search Page',
  checkout: 'Checkout',
  defaultBuyer: 'defaultbuyer',
};
//-----------------------

export const rfkidOf = {
  shopsResults: 'rfkid_11',
  searchResults: 'rfkid_7',
  plpResults: 'rfkid_10',
  search: 'rfkid_6',
};

const getEncryptedProductPath = (key?: string) => {
  if (key) {
    return key
      ?.split('/')
      ?.map((path) => path.replace(/[\W_]+/g, '-'))
      ?.join('/');
  }
  return null;
};
export const getProductDetailPath = (product: BuyerProductWithXp) => {
  return `/categories/${getEncryptedProductPath(
    product?.xp?.Path
  )}/${product?.xp?.ParentFamilyName?.replace(/[\W_]+/g, '-')}/${product?.ParentID}?sku=${
    product?.xp?.UPC
  }`?.toLowerCase();
};

export const getProductPromoTag = (
  promoTagData: Nullable<PromoTagXp>[],
  storeDataAreaId: string | undefined,
  storePriceGroup: string[] | undefined
) => {
  if (!Array.isArray(promoTagData)) return;
  return [...promoTagData]
    ?.sort((a, b) => (a?.Priority ?? 0) - (b?.Priority ?? 0))
    .find((promo) => {
      if (!promo) {
        return false;
      }
      const dataAreaIdMatches =
        promo.IgnoreDataAreaId || (storeDataAreaId && promo.DataAreaId === storeDataAreaId);

      const priceGroupMatches =
        promo.IgnorePriceGroup || !promo.PriceGroup || storePriceGroup?.includes(promo.PriceGroup);

      return dataAreaIdMatches && priceGroupMatches;
    })?.Text;
};
export const getTruncatedProductPromoTag = (promoTag: string, maxLengthForPromoTag?: number) => {
  if (!promoTag) return;
  if ((maxLengthForPromoTag && promoTag?.length <= maxLengthForPromoTag) || !maxLengthForPromoTag) {
    return promoTag;
  }
  // Find the last space within the maxLengthForPromoTag limit
  let truncated = promoTag?.slice(0, maxLengthForPromoTag);
  const lastSpace = truncated.lastIndexOf(' ');
  // If there's a space, truncate at that space, otherwise use the maxLengthForPromoTag
  if (lastSpace > -1) {
    truncated = truncated.slice(0, lastSpace);
  }
  return truncated + '...';
};

export const pointPerDollar = 5;

export const retailUnitMeasureFieldName = 'RetailUnit&Measure';
export const getProductDetailPathForSiteMap = (product: BuyerProductWithXp, siteName: string) => {
  return `${extractDomainUrlFromConfig(siteName)}${getProductDetailPath(product)}`;
};

export const errorMessages = {
  invalidAddress: 'The address cannot be validated and cannot be saved. Please correct the address',
  emailExists: 'Email already exist.',
  invalidCVV: 'Payment Authorization Failed',
};

export const locationType = {
  rooftop: 'ROOFTOP',
};

export const statusMessage = {
  inProgress: 'In Progress',
  loginInProgress: 'Login in Progress',
  registeringAccount: 'Creating Account',
};

export const formErrorFlags = {
  isBlacklisted: 'BlacklistedUser',
  passwordMatch: 'PasswordMatch',
  samePassword: 'SamePassword',
  linkExpired: 'LinkExpire',
  emailExists: 'EmailAlreadyExist',
  userAlreadyExist: 'UserAlreadyExist',
};
