import { GTM_EVENT, GTMLabels } from 'components/helpers/Constants';
import { useTheme } from 'lib/context/ThemeContext';
import React, { useContext, useEffect, useState } from 'react';
import { REQUEST_METHOD } from 'src/helpers/MyPetsHelper';
import useDictionary from 'src/hooks/useDictionary';
import { apiRequest, apiRequestExtended } from 'src/utils/apiWrapper';
import { tv } from 'tailwind-variants';
import { StoreProps } from 'components/StoreLocatorHelpers/GenericStoreLocator';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'components/helpers/IconHelper';
import ButtonHelper from 'components/helpers/Button';
import DatePicker from 'react-datepicker';
import ModalWrapper from 'components/helpers/ModalWrapper';
import LinkHelper from 'components/helpers/LinkHelper';
import ImageHelper from 'components/helpers/ImageHelper';
import { ChangeStoreVariants } from 'components/ProductDetail/ChangeStorePopup/ChangeStoreVariants';
import ComponentContext from 'lib/context/ComponentContext';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { updateUser } from 'src/redux/ocUser';
import Loader from 'components/Loader/Loader';
import { groomingStep4Data, groomingStepsData } from 'src/utils/sendGTMEvent';
import { isValid, parse } from 'date-fns';
interface OpenTime {
  Id: string;
  DateDisplayValue: string;
  BeginAtTimeDisplayValue: string;
  BeginAtTime: string;
  BeginAtTimeUtcString: string;
  Groomerid: number;
  Groomername: string;
  Dealid: number;
}

interface DaySchedule {
  Date: string;
  DateString: string;
  DayOfWeek: string;
  DateDisplay: string;
  OpenTimes: OpenTime[];
  NoOpenTimeSlotsAvailable: boolean;
}

interface NextAvailableAppList {
  OpenDates: DaySchedule[];
  CustomFieldIdForPetBreed?: number;
  StoreTimeZone?: string;
  GroomingServiceIds?: string; // This should be updated to the correct type if it's not a string
  NoOpenDatesOrOpenTimeSlotsAvailable?: boolean;
  RemainingOpenDatesString?: string[];
}

interface Groomer {
  GroomerId: number;
  GroomerName: string;
}

const { selectStoreCTA } = ChangeStoreVariants({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});
const ScheduleAppointmentStep4TV = tv(
  {
    slots: {
      base: ['step3-wrapper'],
      step1HeadingWrapper: ['flex flex-row justify-between'],
      step1HeadingTitle: [
        'font-heading-desk-small-bold text-heading-desk-small-bold leading-heading-desk-small-bold text-color-text-black',
      ],
      step1HeadingLink: [
        'text-color-brand-primary-1-base',
        'relative',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'underline',
        'cursor-pointer',
      ],
      chooseOneWrapper: [''],
      note: [''],
      noteText: [
        'text-body-large-regular font-body-large-regular leading-body-large-regular text-color-text-black my-[12px]',
      ],
      searchByWrapperText: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'text-color-text-black',
        'my-[12px]',
      ],
      searchByWrapper: ['flex lg:flex-row flex-col'],
      serviceControlWrapper: ['flex flex-row mr-[12px]'],
      inputControl: ['mr-[12px] relative'],
      seviceInfoWrapper: ['flex flex-col'],
      serviceName: [
        'font-body-large-bold text-body-large-bold leading-body-large-bold text-color-text-black',
      ],
      availableAppointementWrapper: ['available-appointment-wrapper'],
      availableTimeNote: [
        'text-heading-desk-medium-bold font-heading-desk-medium-bold heading-heading-desk-medium-bold my-[24px] block',
      ],
      daysListing: [
        'day-listing-item  text-body-small-regular font-body-small-regular leading-body-small-regular my-[12px] block',
      ],
      timeItem: [
        'p-[8px] text-body-small-regular font-body-small-regular leading-body-small-regular text-color-text-brand-1 border rounded-[4px] border-color-border-brand cursor-pointer',
      ],
      finalStep: ['user-consent-wrap pt-[15px]'],
      collectConcent: [],
      telephoneInfo: ['mb-[12px] flex flex-row justify-between items-center'],

      checkbox: [
        'w-[24px]',
        'h-[24px]',
        'outline-color-border-mid',
        'border',
        'appearance-none',
        'bg-color-background-white',
        'peer',
        'checked:bg-color-brand-primary-1-base',
        'rounded-[4px]',
        'border',
        'border-color-border-brand',
      ],
      checkboxIcon: [
        '[&>svg>*>*]:fill-color-background-white absolute [&>svg]:w-[24px] [&>svg]:h-[24px] invisible peer-checked:visible left-0',
      ],
      radioButton:
        'h-6 w-6 cursor-pointer min-h-6 min-w-6 appearance-none border border-color-border-dark rounded-full outline-none shadow-[inset_22px_0px_6px_6px_color-brand-primary-1-base] border-color-border-mid border-2 bg-color-scale-1-white',
      radioButtonFiller:
        'cursor-pointer absolute top-[1px] left-[1px] h-[22px] w-[22px] bg-color-brand-primary-1-base rounded-full',
      formInput: [
        'outline-none',
        'border-2',
        'border-color-border-mid',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:border-color-brand-primary-1-base',
        'focus:placeholder:opacity-0',
        'placeholder:transition-opacity',
        'placeholder:duration-500',
        'focus:border-color-brand-primary-1-base',
        'disabled:bg-color-scale-3-light-mid',
        'min-h-[24px]',
        'min-w-[277px]',
        'py-desk-global-spacing-spacing-3',
        'px-desk-global-spacing-spacing-4',
        'appearance-none',
        'bg-color-scale-1-white',
      ],
      textLargeRegular: [
        'text-body-large-regular font-body-large-regular leading-body-large-regular',
      ],
      dropDownArrow: [
        'appearance-none',
        "bg-[url('/icons/dropdown.svg')]",
        'bg-no-repeat',
        'bg-[position:calc(100%-10px)_center]',
      ],
      dateError: [
        'font-body-small-bold',
        'leading-body-small-bold',
        'text-body-small-bold text-pdp-red',
      ],
      bookedAppointmentConfirmation: ['booked-appointment-confirmation', 'p-desk-padding-tight-y'],
      confirmationImageWrapper: ['object-cover w-full mt-[24px]'],
    },
  },
  { responsiveVariants: ['lg'] }
);

const ScheduleAppointmentStep4 = ({
  data,
  selectedStoreFromStep1,
  step2data,
  myTimeChildID,
  resetStateAndContext,
  resetChildState,
  setResetChildState,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  selectedStoreFromStep1: StoreProps | null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  step2data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  myTimeChildID?: any;
  resetStateAndContext: () => void;
  resetChildState: boolean;
  setResetChildState: (e: boolean) => void;
}) => {
  const {
    base,
    step1HeadingWrapper,
    step1HeadingTitle,
    chooseOneWrapper,
    note,
    noteText,
    searchByWrapperText,
    searchByWrapper,
    serviceControlWrapper,
    inputControl,
    seviceInfoWrapper,
    serviceName,
    availableAppointementWrapper,
    availableTimeNote,
    daysListing,
    timeItem,
    finalStep,
    collectConcent,
    telephoneInfo,
    checkbox,
    checkboxIcon,
    step1HeadingLink,
    radioButton,
    radioButtonFiller,
    formInput,
    dropDownArrow,
    textLargeRegular,
    dateError,
    bookedAppointmentConfirmation,
    confirmationImageWrapper,
  } = ScheduleAppointmentStep4TV({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const { getDictionaryValue } = useDictionary();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userDetails = useSelector((state: any) => state?.ocUser?.user);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedContactOption, setSelectedContactOption] = useState<string>('Mobile');
  const [selectedTimeslot, setSelectedTimeslot] = useState<string>(
    getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny')
  );
  const [showFetchAppointmentLoader, setshowFetchAppointmentLoader] = useState(false);
  const [getMoreTimeSlotsLoader, setMoreTimeSlotsLoader] = useState(false);
  const [nextAvailableApp, setnextAvailableApp] = useState(false);
  const [checkforGroomers, setcheckforGroomers] = useState(false);
  const [selectedGroomerId, setSelectedGroomerId] = useState<number | null>(null);
  const [selectedGroomerName, setSelectedGroomerName] = useState<string>('');
  const [nextAvailableAppList, setnextAvailableAppList] = useState<
    NextAvailableAppList | undefined
  >(undefined);
  const [selectedTime, setSelectedTime] = useState<OpenTime | null>(null);
  const { themeNameUpper } = useTheme();
  const token = useAuthToken();
  const dealID = data?.length > 0 ? parseInt(data[0].dealid, 10) : null;

  // const serviceIDFirst = data?.length > 0 ? `${data[0].serviceid}` : null;
  const [error, setError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [placeholder, setPlaceholder] = useState(
    getDictionaryValue('ScheduleAppointmentsStep4PhoneNumberInitialValue')
  );
  const [saveToProfileChecked, setSaveToProfileChecked] = useState(true);
  const [saveUserConcent, setsaveUserConcent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(userDetails?.Phone || '');
  const [homeNumber, setHomeNumber] = useState(userDetails?.xp?.HomePhone || '');
  const [groomers, setGroomers] = useState<Groomer[]>([]);
  const [searchCriteria, setsearchCriteria] = useState<boolean>(false);
  const [showConfirmationPopup, setshowConfirmationPopup] = useState<boolean>(false);
  const [showStartDateValidation, setshowStartDateValidation] = useState<boolean>(false);
  const [showEndDateValidation, setshowEndDateValidation] = useState<boolean>(false);
  const { sitecoreContext } = useSitecoreContext();
  const [bookAppointmentLoader, setbookAppointmentLoader] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataForStep4, setdataForStep4] = useState<any>(false);
  const { componentContextData } = useContext(ComponentContext);
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  useEffect(() => {
    if (resetChildState) {
      setSaveToProfileChecked(true);
      setsaveUserConcent(false);
      setPhoneNumber(userDetails?.Phone || '');
      setHomeNumber(userDetails?.xp?.HomePhone || '');
      setGroomers([]);
      setsearchCriteria(false);
      setshowConfirmationPopup(false);
      setshowStartDateValidation(false);
      setshowEndDateValidation(false);
      setbookAppointmentLoader(false);
      setdataForStep4(false);
      setResetChildState(false);
    }
  }, [resetChildState]);

  useEffect(() => {
    setPhoneNumber(userDetails?.Phone || '');
    setHomeNumber(userDetails?.xp?.HomePhone || '');
  }, [userDetails, selectedTime]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const serviceIDs =
    data && data.length > 0
      ? data[0].servicedescription === 'Nail & Quick Services' ||
        data[0].servicedescription === 'Puppy Services'
        ? data.length > 1
          ? data
              .slice(1)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((item: any) => item.serviceid)
              .join(',')
          : ''
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data.map((item: any) => item.serviceid).join(',')
      : '';

  const combinedServicePrice = data
    ? data.length > 0
      ? data
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .reduce((acc: number, item: any) => {
            const price = parseFloat(item.serviceprice);
            return acc + (isNaN(price) ? 0 : price);
          }, 0)
          .toFixed(2)
      : '0.00'
    : componentContextData.bookedAppointmentForStep1?.Services &&
      componentContextData.bookedAppointmentForStep1?.Services
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .reduce((acc: number, item: any) => {
          const price = parseFloat(item.Amount);
          return acc + (isNaN(price) ? 0 : price);
        }, 0)
        .toFixed(2);

  function transformItems() {
    if (data && data.length > 0) {
      if (
        (data && data.length > 0 && data[0].servicedescription === 'Nail & Quick Services') ||
        data[0].servicedescription === 'Puppy Services'
      ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return data.slice(1).map((item: any) => ({
          itemName: item?.servicename,
          category: item?.title,
          price: Number(item?.serviceprice).toFixed(2),
        }));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return data.map((item: any) => ({
          itemName: item?.servicename,
          category: item?.title,
          price: Number(item?.serviceprice).toFixed(2),
        }));
      }
    }
    if (componentContextData.bookedAppointmentForStep1?.Services) {
      const dealIds = componentContextData.dealIds;

      const findTitle = (serviceId: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const deal = dealIds.find((d: any) => d.Id === serviceId);
        return deal?.title || '';
      };

      if (
        componentContextData.bookedAppointmentForStep1?.Services[0].Description ===
          'Nail & Quick Services' ||
        componentContextData.bookedAppointmentForStep1?.Services[0].Description === 'Puppy Services'
      ) {
        return componentContextData.bookedAppointmentForStep1?.Services?.slice(1).map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => ({
            itemName: item.Name,
            category: findTitle(item.Id),
            price: Number(item.Amount).toFixed(2),
          })
        );
      } else {
        return componentContextData.bookedAppointmentForStep1?.Services?.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => ({
            itemName: item.Name,
            category: findTitle(item.Id),
            price: Number(item.Amount).toFixed(2),
          })
        );
      }
    }
  }

  function formatedDateforGTM(dateString?: string) {
    if (!dateString) {
      return '';
    }

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      // Handle invalid date string case
      return '';
    }

    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
      .getDate()
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;

    return formattedDate;
  }

  // function for calculating dog's age
  function calculateAge(dateString: string): number {
    const today = new Date();
    const dob = new Date(dateString);

    if (isNaN(dob.getTime())) {
      throw new Error('Invalid date');
    }

    // Calculate the difference in milliseconds
    const ageInMilliseconds = today.getTime() - dob.getTime();

    // Convert the difference in milliseconds to years
    const ageInYears = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));

    // If the calculated age is less than 1 year, return 0, otherwise return the age
    return ageInYears > 0 ? ageInYears : 0;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const appointmentConfirmationPopupContent = (sitecoreContext as any)?.route?.fields
    ?.groomingConfiguration?.[0]?.fields;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [startDate, setStartDate] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [endDate, setEndDate] = useState<any>(null);
  const [automatedServiceIDs, setautomatedServiceIDs] = useState<string>('');
  const [automatedDealIDs, setautomatedDealIDs] = useState<string>('');
  const [userDataUpdateWarning, setUserDataUpdateWarning] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [, setselectedServicesData] = useState<any>(null);
  const [donotUpdateUser, setDonotUpdateUser] = useState<boolean>(true);
  const [showSelectedAppointmentData, setShowSelectedAppointmentData] = useState<boolean>(false);
  const [notimeslotserror, setNoTimeslotsError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [remainingTime, setRemainingTime] = useState<any>([]);
  const [showloadmore, setShowLoadMore] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [endInputValue, setEndInputValue] = useState<string>('');
  const [hasScrolled, setHasScrolled] = useState(false);
  const dispatch = useOcDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatDate = (dateStr: any) => {
    if (!dateStr) {
      setEndDate(null);
      !startDate && setStartDate(null);
      return null;
    } else {
      const date = new Date(dateStr);
      const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
  };

  const getMaxEndDate = () => {
    if (startDate) {
      const maxEndDate = new Date(startDate);
      maxEndDate.setDate(maxEndDate.getDate() + 30);
      return maxEndDate;
    }
    return null;
  };

  const getGroomingServicesForAutomation = async () => {
    if (componentContextData.bookedAppointmentForStep1 !== undefined) {
      const getGroomingServicesForAutomationPayload = {
        SelectedPetId: componentContextData.bookedAppointmentForStep1?.ChildId,
        SelectedPetName: componentContextData.bookedAppointmentForStep1?.ChildName,
        SelectedPetBreedName: componentContextData.bookedAppointmentForStep1?.ChildBreed,
        SelectedStoreLocationId: componentContextData.bookedAppointmentForStep1?.Location?.Id,
        ServicesId: appointmentConfirmationPopupContent?.serviceIds?.value,
        WebId: userDetails?.xp?.WebId,
      };

      const headersData = {
        site: themeNameUpper,
        Authorization: token,
      };
      // // setPetLoader(true);
      const getGroomingServicesAutomatedUrl = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/grooming-services`;
      try {
        const options = {
          method: REQUEST_METHOD?.post,
          headers: headersData,
          data: getGroomingServicesForAutomationPayload,
        };
        const res = await apiRequest(getGroomingServicesAutomatedUrl, options);

        if (res) {
          setselectedServicesData(res);
        }
        // setPetList(res);
      } catch (err) {
        console.error('@@Error', err);
      }
    }
  };

  const getNextAvailableSlots = async () => {
    setshowFetchAppointmentLoader(true);
    const getNextAvailableSlotsPayload = {
      GroomingStoreLocationId:
        selectedStoreFromStep1?.xp_onlinebookinglocation ||
        componentContextData.bookedAppointmentForStep1?.Location.Id,
      GroomingServiceIds: (serviceIDs as string) || automatedServiceIDs,
      PetBreedName:
        step2data?.BreedName ||
        componentContextData.bookedAppointmentForStep1?.ChildBreed ||
        step2data?.breedname,
      MyTimeChildId:
        myTimeChildID?.myTimeChildId || componentContextData.bookedAppointmentForStep1?.ChildId,
      webId: userDetails?.xp?.WebId,
      //MaxAvailableDatesLoadCount: 5,
      UseMultipleThreadsForTimeSlots: true,
    };

    const headersData = {
      site: themeNameUpper,
      Authorization: token,
    };
    const getNextAvailableSlotsUrl = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/next-available-timeslots`;
    try {
      const options = {
        method: REQUEST_METHOD?.post,
        headers: headersData,
        data: getNextAvailableSlotsPayload,
      };
      const res = (await apiRequest(getNextAvailableSlotsUrl, options)) as NextAvailableAppList;
      setshowFetchAppointmentLoader(false);
      if (res && res !== null) {
        setnextAvailableAppList(res as NextAvailableAppList);
        setRemainingTime(res?.RemainingOpenDatesString);
        if (res?.RemainingOpenDatesString && res?.RemainingOpenDatesString.length > 0) {
          setShowLoadMore(true);
        } else {
          setShowLoadMore(false);
        }
      } else if (res == null) {
        setsearchCriteria(true);
        setNoTimeslotsError(true);
      }
    } catch (err) {
      console.error('@@Error', err);
    }
  };

  const getNextAvailableSlotsByGroomer = async () => {
    if (startDate && endDate) {
      setshowFetchAppointmentLoader(true);
      const getNextAvailableSlotsByGroomerPayload = {
        OpenDates: nextAvailableAppList?.RemainingOpenDatesString,
        GroomingStoreLocationId:
          selectedStoreFromStep1?.xp_onlinebookinglocation ||
          componentContextData.bookedAppointmentForStep1?.Location.Id,
        GroomingServiceIds: (serviceIDs as string) || automatedServiceIDs,
        PetBreedName:
          step2data?.BreedName ||
          componentContextData.bookedAppointmentForStep1?.ChildBreed ||
          step2data?.breedname,
        MyTimeChildId:
          myTimeChildID?.myTimeChildId || componentContextData.bookedAppointmentForStep1?.ChildId,
        TimeFrame: selectedTimeslot === 'Any' ? 0 : selectedTimeslot === 'AM' ? 1 : 2,
        StartDate: startDate as string,
        EndDate: endDate as string,
        GroomerId: selectedGroomerId,
        webId: userDetails?.xp?.WebId,
        // MaxAvailableDatesLoadCount: 5,
        UseMultipleThreadsForTimeSlots: true,
      };

      const headersData = {
        site: themeNameUpper,
        Authorization: token,
      };
      const getNextAvailableSlotsByGroomerUrl = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/timeslots-by-date-range-and-groomer`;
      try {
        const options = {
          method: REQUEST_METHOD?.post,
          headers: headersData,
          data: getNextAvailableSlotsByGroomerPayload,
        };
        const res = (await apiRequest(
          getNextAvailableSlotsByGroomerUrl,
          options
        )) as NextAvailableAppList;
        setshowFetchAppointmentLoader(false);
        if (res !== null) {
          setnextAvailableApp(true);
          setsearchCriteria(true);
          setnextAvailableAppList(res as NextAvailableAppList);
          setbookAppointmentLoader(false);
          setNoTimeslotsError(false);
          setRemainingTime(res?.RemainingOpenDatesString);
          if (res?.RemainingOpenDatesString && res?.RemainingOpenDatesString.length > 0) {
            setShowLoadMore(true);
          } else {
            setShowLoadMore(false);
          }
        } else if (res == null) {
          setsearchCriteria(true);
          setNoTimeslotsError(true);
        }
      } catch (err) {
        console.error('@@Error', err);
      }
    } else {
      if (!startDate) {
        setshowStartDateValidation(true);
      } else if (startDate && !endDate) {
        setshowStartDateValidation(false);
        setshowEndDateValidation(true);
      }
    }
  };

  const getMoreTimeSlots = async () => {
    setMoreTimeSlotsLoader(true);

    const getMoreTimeSlotsPayload = {
      OpenDates: remainingTime.slice(
        0,
        appointmentConfirmationPopupContent?.availableAppointmentDatesThreshold?.value
      ),
      GroomingStoreLocationId:
        selectedStoreFromStep1?.xp_onlinebookinglocation ||
        componentContextData.bookedAppointmentForStep1?.Location.Id,
      GroomingServiceIds: (serviceIDs as string) || automatedServiceIDs,
      PetBreedName:
        step2data?.BreedName || componentContextData.bookedAppointmentForStep1?.ChildBreed,
      MyTimeChildId:
        myTimeChildID?.myTimeChildId || componentContextData.bookedAppointmentForStep1?.ChildId,
      TimeFrame: selectedTimeslot === 'Any' ? 0 : selectedTimeslot === 'AM' ? 1 : 2,
      StartDate: startDate as string,
      EndDate: endDate as string,
      GroomerId: selectedGroomerId,
      webId: userDetails?.xp?.WebId,
      CustomFieldIdForPetBreed: nextAvailableAppList?.CustomFieldIdForPetBreed,
      GroomingStoreLocationTimezone: nextAvailableAppList?.StoreTimeZone,
    };

    const headersData = {
      site: themeNameUpper,
      Authorization: token,
    };
    const getMoreTimeSlotsURL = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/get-more-available-time-slots`;
    try {
      const options = {
        method: REQUEST_METHOD?.post,
        headers: headersData,
        data: getMoreTimeSlotsPayload,
      };
      const res = await apiRequest(getMoreTimeSlotsURL, options);

      if (res !== null) {
        const newData = res as NextAvailableAppList;
        setnextAvailableAppList((prevList: NextAvailableAppList | undefined) => ({
          ...prevList,
          OpenDates: [
            ...(prevList?.OpenDates || []),
            ...(Array.isArray(newData?.OpenDates) ? newData.OpenDates : []),
          ],
          CustomFieldIdForPetBreed: newData.CustomFieldIdForPetBreed,
          GroomingServiceIds: newData.GroomingServiceIds, // Adjust this if it's not a string
          NoOpenDatesOrOpenTimeSlotsAvailable:
            newData.NoOpenDatesOrOpenTimeSlotsAvailable ??
            prevList?.NoOpenDatesOrOpenTimeSlotsAvailable,
          RemainingOpenDatesString: [
            ...(prevList?.RemainingOpenDatesString || []),
            ...(Array.isArray(newData.RemainingOpenDatesString)
              ? newData.RemainingOpenDatesString
              : []),
          ],
          // Merge other properties as needed
        }));

        const threshold =
          appointmentConfirmationPopupContent?.availableAppointmentDatesThreshold?.value;

        const newRemainingTime = remainingTime.slice(threshold);

        setRemainingTime(newRemainingTime);

        setMoreTimeSlotsLoader(false);
        if (
          remainingTime.length <=
          appointmentConfirmationPopupContent?.availableAppointmentDatesThreshold?.value
        ) {
          setShowLoadMore(false);
        } else {
          setShowLoadMore(true);
        }
      }
    } catch (err) {
      console.error('@@Error', err);
    }
  };

  const LoaderComponent = ({ loaderText }: { loaderText: string }) => {
    return (
      <div className={'items-center flex flex-row border-b-0 gap-[7px] my-[24px]'}>
        <Loader />
        <span>{loaderText}</span>
      </div>
    );
  };

  const showAppointementData = (selectedOption: string) => {
    if (selectedOption && selectedOption !== '') {
      setsearchCriteria(false);
      setNoTimeslotsError(false);
      setSelectedTime(null);
      setStartDate(null);
      setEndDate(null);
      setnextAvailableAppList(undefined);
      setMoreTimeSlotsLoader(false);
      setEndInputValue('');
      setEndDate('');
      setStartDate('');
      setInputValue('');
      setError('');
      setEndDateError('');
      if (selectedOption === 'profanddate') {
        // setrenderProfAndData(true);
        setcheckforGroomers(true);
        setnextAvailableApp(false);
        setStartDate(null);
        setEndDate(null);
        setSelectedTimeslot(getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny'));
        setSelectedGroomerName('');
        setSelectedGroomerId(null);
        setshowStartDateValidation(false);
        setshowEndDateValidation(false);
        setsaveUserConcent(false);
      } else {
        setnextAvailableApp(true);
        setcheckforGroomers(false);
        setsaveUserConcent(false);
      }
    }
  };

  const handleMobileCheckboxChange = () => {
    setError('');
    setSelectedContactOption('Mobile');
    const phone = userDetails?.Phone || phoneNumber;
    if (!phone) {
      setError(getDictionaryValue('ScheduleAppointmentsStep4PhoneNumberRequired'));
    } else {
      setError('');
    }
    handleChange(phone, setPhoneNumber);
  };

  const handleHomeCheckboxChange = () => {
    setError('');
    setSelectedContactOption('Home');
    const HomeNumber = userDetails?.xp?.HomePhone || '';
    if (!HomeNumber) {
      setPlaceholder(getDictionaryValue('ScheduleAppointmentsStep4PhoneNumberInitialValue'));
      setError(getDictionaryValue('ScheduleAppointmentsStep4PhoneNumberRequired'));
    } else {
      setError('');
    }
    handleChange(HomeNumber, setHomeNumber);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (newValue: string, setter: any) => {
    if (newValue === '') {
      setter(newValue);
      setError(getDictionaryValue('ScheduleAppointmentsStep4PhoneNumberRequired'));
      return;
    }
    // Remove all non-digit characters
    newValue = newValue?.replace(/\D/g, '');
    // Format the phone number
    if (newValue?.length <= 3) {
      newValue = newValue;
    } else if (newValue?.length <= 6) {
      newValue = `(${newValue?.slice(0, 3)}) ${newValue?.slice(3)}`;
    } else {
      newValue = `(${newValue?.slice(0, 3)}) ${newValue?.slice(3, 6)}-${newValue?.slice(6, 10)}`;
    }

    // Validate the formatted number
    const regex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!regex.test(newValue) && newValue !== '') {
      setError(getDictionaryValue('ScheduleAppointmentsStep4PhoneNumberInvalid'));
    } else {
      setError('');
    }

    setter(newValue);
  };

  const checkConcent = () => {
    if (saveUserConcent === false) {
      setsaveUserConcent(true), setShowSelectedAppointmentData(true);
    } else {
      setsaveUserConcent(false);
    }
  };

  const profileChecked = () => {
    setSaveToProfileChecked(!saveToProfileChecked);
  };

  useEffect(() => {
    if (selectedContactOption === 'Mobile') {
      setPhoneNumber(handleChange(userDetails?.Phone, setPhoneNumber));
    }
    if (selectedContactOption === 'Home') {
      setHomeNumber(handleChange(userDetails?.xp?.HomePhone, setHomeNumber));
    }
  }, [selectedOption]);

  const formatPhoneNumber = (phoneNumber: number) => {
    if (!phoneNumber) return phoneNumber;
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };
  const schdueleAppointment = async () => {
    const items = transformItems();
    const dogAge = calculateAge(
      step2data?.Birthdate || componentContextData.bookedAppointmentForStep1?.PetDob
    );

    groomingStep4Data({
      event: GTM_EVENT?.groomingSubmit,
      storeId: myStoreData?.storeId,
      groomingStoreId:
        selectedStoreFromStep1?.storeid ||
        componentContextData.bookedAppointmentForStep1?.Location.Id,
      stepName: GTMLabels?.groomingSubmit,
      revenue: combinedServicePrice,
      scheduledTime: selectedTime?.BeginAtTimeDisplayValue,
      scheduledDate: formatedDateforGTM(selectedTime?.BeginAtTimeUtcString),
      searchBy:
        selectedOption === 'profanddate'
          ? GTMLabels?.groomingSearchByProfessionalDateRange
          : GTMLabels?.groomingNextAvailableAppointment,
      dogBreed: step2data?.BreedName || componentContextData.bookedAppointmentForStep1?.ChildBreed,
      dogAge: dogAge,
      dogGender: step2data?.GenderName, // dog's gender
      quantity:
        data && data.length > 0
          ? data[0]?.servicedescription === 'Nail & Quick Services' ||
            data[0]?.servicedescription === 'Puppy Services'
            ? data.length - 1
            : data.length
          : componentContextData.bookedAppointmentForStep1?.Services[0]?.description ===
              'Nail & Quick Services' ||
            componentContextData.bookedAppointmentForStep1?.Services[0]?.description ===
              'Puppy Services'
          ? componentContextData.bookedAppointmentForStep1?.Services.length - 1
          : componentContextData.bookedAppointmentForStep1?.Services.length,
      items: items,
    });

    setUserDataUpdateWarning(false);
    setbookAppointmentLoader(true);

    const schdueleAppointmentPayload = {
      Phonenumber:
        selectedContactOption === 'Mobile'
          ? phoneNumber !== ''
            ? formatPhoneNumber(phoneNumber) || formatPhoneNumber(userDetails?.Phone)
            : ''
          : selectedContactOption === 'Home'
          ? formatPhoneNumber(homeNumber)
          : formatPhoneNumber(userDetails?.xp?.HomePhone),
      ExistingCellNumber: formatPhoneNumber(userDetails?.Phone),
      PhoneType: selectedContactOption === 'Home' ? 2 : 1,
      SaveToProfile: saveToProfileChecked,
      DonotUpdateUser: donotUpdateUser,
      ExistingHomePhoneNumber: formatPhoneNumber(userDetails?.xp?.HomePhone),
      GroomingStoreLocationId:
        selectedStoreFromStep1?.xp_onlinebookinglocation ||
        componentContextData.bookedAppointmentForStep1?.Location.Id,
      GroomingServiceIds: (serviceIDs as string) || automatedServiceIDs,
      DealId: dealID || automatedDealIDs,
      GroomerId: selectedTime?.Groomerid,
      BeginAtTimeUtcString: selectedTime?.BeginAtTimeUtcString,
      MyTimeChildId:
        myTimeChildID?.myTimeChildId || componentContextData.bookedAppointmentForStep1?.ChildId,
      CustomFieldIdForPetBreeed: nextAvailableAppList?.CustomFieldIdForPetBreed, // need to look into this
      PetBreedName:
        step2data?.BreedName ||
        componentContextData.bookedAppointmentForStep1?.ChildBreed ||
        step2data?.breedname,
      webId: userDetails?.xp?.WebId,
      firstName: userDetails?.FirstName,
      lastName: userDetails?.LastName,
      email: userDetails?.Email,
    };

    const headersData = {
      site: themeNameUpper,
      Authorization: token,
    };
    const getNextAvailableSlotsUrl = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/schedule-appointment`;
    try {
      const options = {
        method: REQUEST_METHOD?.post,
        headers: headersData,
        data: schdueleAppointmentPayload,
      };
      const res = await apiRequestExtended(getNextAvailableSlotsUrl, options);
      if (res?.status === 201) {
        setbookAppointmentLoader(false);
        setshowConfirmationPopup(true);
      } else {
        console.error('Failed to schedule the appointment.');
        setbookAppointmentLoader(false);
      }
    } catch (err) {
      console.error('@@Error', err);
    }
  };

  const normalizePhoneNumber = (phone: string | number) => {
    return phone.toString().replace(/\D/g, '');
  };

  const checkForUpdatedData = async () => {
    const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber || '');
    const normalizedUserDetailsPhone = normalizePhoneNumber(userDetails?.Phone || '');
    const normalizedHomePhoneNumber = normalizePhoneNumber(homeNumber || '');
    const normalizedUserDetailsHomePhone = normalizePhoneNumber(userDetails?.xp?.HomePhone || '');

    if (selectedContactOption === 'Mobile')
      if (normalizedPhoneNumber !== normalizedUserDetailsPhone) {
        setUserDataUpdateWarning(true);
      } else {
        setUserDataUpdateWarning(false);
        schdueleAppointment();
      }
    if (selectedContactOption === 'Home') {
      if (normalizedHomePhoneNumber !== normalizedUserDetailsHomePhone) {
        setUserDataUpdateWarning(true);
      } else {
        setUserDataUpdateWarning(false);
        schdueleAppointment();
      }
    }
  };

  const findGroomer = async () => {
    setshowFetchAppointmentLoader(true);
    const schdueleAppointmentPayload = {
      SelectedLocationId:
        selectedStoreFromStep1?.xp_onlinebookinglocation ||
        componentContextData.bookedAppointmentForStep1?.Location.Id,
      SelectedServicesIds: serviceIDs || automatedServiceIDs,
      webId: userDetails?.xp?.WebId,
    };
    const headersData = {
      site: themeNameUpper,
      Authorization: token,
    };
    const gfindGroomerUrl = `${process.env.NEXT_PUBLIC_API_ENDPOINT}/available-groomers`;
    try {
      const options = {
        method: REQUEST_METHOD?.post,
        headers: headersData,
        data: schdueleAppointmentPayload,
      };
      const res = await apiRequest(gfindGroomerUrl, options);
      if (res && Array.isArray(res) && res.length > 0) {
        setshowFetchAppointmentLoader(false);
        setGroomers(res);
      } else {
        setshowFetchAppointmentLoader(false);
        setNoTimeslotsError(true);
        console.error('Failed to schedule the appointment.');
      }
    } catch (err) {
      console.error('@@Error', err);
    }
  };

  const handleModalClose = () => {
    setshowConfirmationPopup(false);
    resetStateAndContext();
  };

  const handleDataUpdateWarningModalClose = () => {
    setUserDataUpdateWarning(false);
  };

  useEffect(() => {
    setdataForStep4(data);
  }, [data]);

  useEffect(() => {
    if (componentContextData?.resetStep4 === false && !data) {
      setdataForStep4(true);
    } else if (componentContextData?.resetStep4 === false) {
      setdataForStep4(true);
    }
  }, [componentContextData?.resetStep4, data]);
  useEffect(() => {
    if (componentContextData.bookedAppointmentForStep1 && !data) {
      setdataForStep4(componentContextData.bookedAppointmentForStep1);
      // getGroomingServicesForAutomation();
    }
  }, [componentContextData, data]);

  useEffect(() => {
    if (componentContextData.bookedAppointmentForStep4 !== null && !data) {
      setdataForStep4(componentContextData.bookedAppointmentForStep4);
      getGroomingServicesForAutomation();
      const element = document.getElementsByClassName('step-4-wrapper')[0] as HTMLElement;
      if (componentContextData.bookedAppointmentForStep4) {
        if (!hasScrolled) {
          if (element) {
            window.scrollTo({
              top: element.offsetTop,
              behavior: 'smooth',
            });
          }
          setHasScrolled(true);
        }
      }
    }
    const AutomatedserviceIds = componentContextData.bookedAppointmentForStep1?.Services.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (service: any) => service.Id
    ).join(',');
    setautomatedServiceIDs(AutomatedserviceIds);
    if (componentContextData.dealIds) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dealIds = componentContextData.dealIds.map((item: any) => item.DealId.toString());
      // Joining the DealIds with comma

      const firstDealId = dealIds[0];
      setautomatedDealIDs(firstDealId);
    }

    if (componentContextData?.resetStep4 === true) {
      setdataForStep4(false);
      setSelectedOption('');
      setsearchCriteria(false);
      setSelectedTimeslot('');
      setnextAvailableApp(false);
      setcheckforGroomers(false);
      setShowSelectedAppointmentData(false);
      setNoTimeslotsError(false);
      setbookAppointmentLoader(false);
      setEndDate('');
      setStartDate('');
    }
  }, [componentContextData]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleStartDateChange = (date: any) => {
    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setDate(currentDate.getDate() + 90);

    if (date !== null) {
      setEndInputValue('');
      setEndDate('');
      setEndDate(null);
    } else {
      setEndDate(null);
      setStartDate(null);
    }
  };

  //formiatting in MM/DD/YYY format
  const formatDateString = (dateString: string): string => {
    const match = dateString.match(/^(\d{0,2})(\d{0,2})(\d{0,4})$/);
    if (!match) return dateString;

    const [, month, day, year] = match;
    let formattedDate = '';
    if (month) formattedDate += month;
    if (day) formattedDate += `/${day}`;
    if (year) formattedDate += `/${year}`;

    return formattedDate;
  };

  //validationg the date
  const formatInputValue = (e: React.ChangeEvent<HTMLInputElement>, isEndDate: boolean): string => {
    const value = e?.target?.value;
    let newValue = value.replace(/[^\d]/g, '');
    if (value?.length <= 10) {
      newValue = value.replace(/[^\d]/g, '');
      const month = newValue.slice(0, 2);
      const day = newValue.slice(2, 4);
      const year = newValue.slice(6);
      const inputYear = newValue?.slice(4);
      const currentYear = new Date()?.getFullYear();
      const cursorPosition = e.target.selectionStart || 0;
      const formattedDate = formatDateString(newValue);
      !isEndDate && setInputValue(formattedDate);
      isEndDate && setEndInputValue(formattedDate);
      // Calculate new cursor position based on changes made to the string length
      const newCursorPosition = cursorPosition + (formattedDate.length - value.length);

      // Use requestAnimationFrame to ensure the cursor position is set after the value update
      requestAnimationFrame(() => {
        e.target.setSelectionRange(newCursorPosition, newCursorPosition);
      });

      const validateDatePart = (
        part: string | undefined,
        max: number,
        errorMessage: string,
        setErrorFunc: (msg: string) => void
      ) => {
        if (part && part?.length >= 1) {
          Number(part) > max ? setErrorFunc(errorMessage) : setErrorFunc('');
        }
      };

      if (!isEndDate) {
        validateDatePart(month, 12, getDictionaryValue('InvalidDate'), setError);
        validateDatePart(day, 31, getDictionaryValue('InvalidDate'), setError);
      } else {
        validateDatePart(month, 12, getDictionaryValue('InvalidDate'), setEndDateError);
        validateDatePart(day, 31, getDictionaryValue('InvalidDate'), setEndDateError);
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const maxDate = new Date();
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 30);
      maxDate.setDate(currentDate.getDate() + 90);

      //set an error if year is invalid or if valid date then set selected date.
      if (year?.length < 2 && !isEndDate) {
        setError(getDictionaryValue('InvalidDate'));
      } else if (year?.length < 2) {
        setEndDateError(getDictionaryValue('InvalidDate'));
      } else {
        const parsedDate = parse(formattedDate, 'MM/dd/yyyy', new Date());
        const inputDate = new Date(formattedDate);

        if (Number(inputYear) > Number(currentYear) && !isEndDate && month <= '12' && day <= '31') {
          setError(getDictionaryValue('DateShouldNintyDays')); //'date cannot be in the future'
        } else if ((month > '12' || day > '31') && !isEndDate) {
          setError(getDictionaryValue('InvalidDate'));
        } else if (!isValid(parsedDate)) {
          if (!isEndDate) {
            setError(getDictionaryValue('InvalidDate')); //Invalid date
          } else {
            setEndDateError(getDictionaryValue('InvalidDate')); //Invalid date
          }
        } else if (!isEndDate) {
          if (inputDate < currentDate) {
            setError(getDictionaryValue('DateShouldNotPast')); //'Date should not be in the past'
          } else if (inputDate > maxDate) {
            setError(getDictionaryValue('DateShouldNintyDays')); //'Date should not be more than 90 days in the future'
          } else {
            setStartDate(formattedDate);
            setError('');
          }
        } else if (isEndDate) {
          if (inputDate < new Date(startDate)) {
            setEndDateError(getDictionaryValue('EndDateCanNotBeLessThanStartDate')); //End date can not be less than start date.
          } else if (inputDate > endDate) {
            setEndDateError(getDictionaryValue('EndDateWithin30Days')); //End date should be within 30 days of start date.
          } else {
            setEndDate(formattedDate);
            setEndDateError('');
          }
        }
      }
      return newValue;
    }
    return inputValue;
  };

  return (
    <div>
      <div className={base()}>
        <div className={(step1HeadingWrapper(), 'mb-[10px] flex flex-row justify-between')}>
          <p className={step1HeadingTitle()}>
            {getDictionaryValue('ScheduleAppointmentsStep4Heading')}
          </p>
          {showSelectedAppointmentData && componentContextData?.resetStep4 === false && (
            <div className="step-4-edit">
              <p
                className={step1HeadingLink()}
                onClick={(e) => {
                  e?.preventDefault(), setSelectedOption('');
                  setsearchCriteria(false);
                  setSelectedTimeslot('');
                  setnextAvailableApp(false);
                  setcheckforGroomers(false);
                  setShowSelectedAppointmentData(false);
                }}
              >
                {getDictionaryValue('EditCTA')}
              </p>
            </div>
          )}
        </div>
        {componentContextData?.resetStep4 === false && (
          <>
            {dataForStep4 && (
              <div className={chooseOneWrapper()}>
                {!showSelectedAppointmentData && (
                  <div className={note()}>
                    <span className={noteText()}>
                      {getDictionaryValue('ScheduleAppointmentsStep4RequiredFieldsMessage')}
                    </span>
                  </div>
                )}
                {!showSelectedAppointmentData && (
                  <div>
                    <p className={searchByWrapperText()}>
                      {getDictionaryValue('ScheduleAppointmentsStep4SearchByText')}
                    </p>
                  </div>
                )}
                {!showSelectedAppointmentData && (
                  <div className={searchByWrapper()}>
                    <label className={serviceControlWrapper()} htmlFor={'profanddate'}>
                      <div className={inputControl()}>
                        <input
                          aria-label="profanddate"
                          id={'profanddate'}
                          type="radio"
                          name="profanddate"
                          value={'profanddate'}
                          checked={selectedOption === 'profanddate'}
                          onChange={(e) => {
                            setSelectedOption(e.target?.value);
                            showAppointementData(e.target?.value);
                            findGroomer();
                          }}
                          className={radioButton()}
                        />
                        {selectedOption === 'profanddate' && (
                          <div className={radioButtonFiller()}></div>
                        )}
                      </div>
                      <div className={seviceInfoWrapper()}>
                        <div className={serviceName()}>
                          {getDictionaryValue(
                            'ScheduleAppointmentsStep4SearchByGroomingProofAndDate'
                          )}
                        </div>
                      </div>
                    </label>
                    <label className={serviceControlWrapper()} htmlFor={'nextavailabledate'}>
                      <div className={inputControl()}>
                        <input
                          aria-label="nextavailabledate"
                          id={'nextavailabledate'}
                          type="radio"
                          name="nextavailabledate"
                          value={'nextavailabledate'}
                          checked={selectedOption === 'nextavailabledate'}
                          onChange={(e) => {
                            setStartDate(null);
                            setEndDate(null);
                            setInputValue('');
                            setEndInputValue('');
                            setSelectedOption(e.target?.value);
                            showAppointementData(e.target?.value);
                            getNextAvailableSlots();
                          }}
                          className={radioButton()}
                        />
                        {selectedOption === 'nextavailabledate' && (
                          <div className={radioButtonFiller()}></div>
                        )}
                      </div>
                      <div className={seviceInfoWrapper()}>
                        <div className={serviceName()}>
                          {getDictionaryValue(
                            'ScheduleAppointmentsStep4SearchByNextAvailableAppointment'
                          )}
                        </div>
                      </div>
                    </label>
                  </div>
                )}
                {/* --------------------------------- CHECK FOR GROOMERS STARTS HERE
            ------------------------------------ */}
                {checkforGroomers && !searchCriteria && (
                  <>
                    {showFetchAppointmentLoader && (
                      <LoaderComponent loaderText={'Fetching Appointments'} />
                    )}
                    {!showFetchAppointmentLoader && !notimeslotserror && (
                      <div className="check-for-groomers-wrapper">
                        <span className={availableTimeNote()}>
                          {getDictionaryValue('ScheduleAppointmentsStep4TimeFrameText')}
                        </span>
                        <div className="time-selection-wrapper flex flex-row">
                          <label
                            className={serviceControlWrapper()}
                            htmlFor={getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny')}
                          >
                            <div className={inputControl()}>
                              <input
                                aria-label="scheduleAppointmentsStep4TimeFrameAny"
                                id={getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny')}
                                type="checkbox"
                                name={getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny')}
                                value={getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny')}
                                checked={
                                  selectedTimeslot ===
                                  getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny')
                                }
                                onChange={() => {
                                  setSelectedTimeslot(
                                    getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny')
                                  );
                                }}
                                className={checkbox()}
                              />
                              {selectedTimeslot ===
                                getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny') && (
                                <IconHelper className={checkboxIcon()} icon={'icon-checkmark'} />
                              )}
                            </div>
                            {getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny')}
                          </label>
                          <label
                            className={serviceControlWrapper()}
                            htmlFor={getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAM')}
                          >
                            <div className={inputControl()}>
                              <input
                                aria-label="scheduleAppointmentsStep4TimeFrameAM"
                                type="checkbox"
                                id={getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAM')}
                                name={getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAM')}
                                value={getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAM')}
                                checked={
                                  selectedTimeslot ===
                                  getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAM')
                                }
                                onChange={() => {
                                  setSelectedTimeslot(
                                    getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAM')
                                  );
                                }}
                                className={checkbox()}
                              />
                              {selectedTimeslot ===
                                getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAM') && (
                                <IconHelper className={checkboxIcon()} icon={'icon-checkmark'} />
                              )}
                            </div>
                            {getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAM')}
                          </label>
                          <label
                            className={serviceControlWrapper()}
                            htmlFor={getDictionaryValue('ScheduleAppointmentsStep4TimeFramePM')}
                          >
                            <div className={inputControl()}>
                              <input
                                aria-label="scheduleAppointmentsStep4TimeFramePM"
                                type="checkbox"
                                id={getDictionaryValue('ScheduleAppointmentsStep4TimeFramePM')}
                                name={getDictionaryValue('ScheduleAppointmentsStep4TimeFramePM')}
                                value={getDictionaryValue('ScheduleAppointmentsStep4TimeFramePM')}
                                checked={
                                  selectedTimeslot ===
                                  getDictionaryValue('ScheduleAppointmentsStep4TimeFramePM')
                                }
                                onChange={() => {
                                  setSelectedTimeslot(
                                    getDictionaryValue('ScheduleAppointmentsStep4TimeFramePM')
                                  );
                                }}
                                className={checkbox()}
                              />
                              {selectedTimeslot ===
                                getDictionaryValue('ScheduleAppointmentsStep4TimeFramePM') && (
                                <IconHelper className={checkboxIcon()} icon={'icon-checkmark'} />
                              )}
                            </div>
                            {getDictionaryValue('ScheduleAppointmentsStep4TimeFramePM')}
                          </label>
                        </div>
                        <div className="select-groomer-dropdown">
                          <span className={availableTimeNote()}>
                            {getDictionaryValue(
                              'ScheduleAppointmentsStep4GroomingProofDropdownText'
                            )}
                          </span>
                          <select
                            aria-label="groomers"
                            value={selectedGroomerId || ''}
                            onChange={(e) => {
                              setSelectedGroomerName(e.target.options[e.target.selectedIndex].id); // Corrected line
                              setSelectedGroomerId(Number(e.target?.value));
                            }}
                            className={formInput({
                              className: textLargeRegular({
                                className: dropDownArrow(),
                              }),
                            })}
                          >
                            <option value="Any" id="Any">
                              {getDictionaryValue('ScheduleAppointmentsStep4TimeFrameAny')}
                            </option>
                            {groomers.map((groomer) => (
                              <option
                                key={groomer.GroomerId}
                                value={groomer.GroomerId}
                                id={groomer.GroomerName}
                              >
                                {groomer.GroomerName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <span className={(note(), 'my-[12px] block')}>
                          {getDictionaryValue(
                            'ScheduleAppointmentsStep4AppointmentScheduleDaysRestrictionText'
                          )}
                        </span>
                        <div className="flex flex-row gap-[24px] items-end">
                          <div className="mb-[4px] ">
                            <span
                              className={clsx(availableTimeNote(), '!my-0', 'lg:max-w-[203px]')}
                            >
                              {getDictionaryValue('ScheduleAppointmentsStep4StartDateLabel')}
                            </span>
                            {showStartDateValidation && (
                              <span className={dateError()}>
                                {getDictionaryValue('ScheduleAppointmentsStep4StartDateRequired')}
                              </span>
                            )}
                            {error && <span className={dateError()}>{error}</span>}
                            <DatePicker
                              selected={startDate}
                              value={inputValue}
                              onChangeRaw={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e?.target?.value !== undefined ? formatInputValue(e, false) : null;
                              }}
                              onSelect={(date) => {
                                const formatedStartDate = formatDate(date);
                                setStartDate(formatedStartDate);
                                setInputValue(formatedStartDate as string);
                                setError('');
                                setshowStartDateValidation(false);
                              }}
                              onChange={handleStartDateChange}
                              minDate={new Date()}
                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                              maxDate={new Date().setDate(new Date().getDate() + 90) as any}
                              placeholderText="MM/DD/YYYY"
                              className="lg:max-w-[203px]"
                            />
                          </div>
                          <div className="mb-[4px] lg:max-w-[203px]">
                            <span
                              className={clsx(availableTimeNote(), '!my-0', 'lg:max-w-[203px]')}
                            >
                              {getDictionaryValue('ScheduleAppointmentsStep4EndDateLabel')}
                            </span>
                            {showEndDateValidation && (
                              <span className={dateError()}>
                                {getDictionaryValue('ScheduleAppointmentsStep4EndDateRequired')}
                              </span>
                            )}
                            {endDateError && <span className={dateError()}>{endDateError}</span>}
                            <DatePicker
                              selected={endDate}
                              value={endInputValue}
                              onChange={(date) => {
                                const formatedEndDate = formatDate(date);
                                setEndDate(formatedEndDate);
                              }}
                              onSelect={(date) => {
                                const formatedEndDate = formatDate(date);
                                setEndInputValue(formatedEndDate as string);
                                setEndDateError('');
                                setshowEndDateValidation(false);
                              }}
                              onChangeRaw={(e: React.ChangeEvent<HTMLInputElement>) => {
                                e?.target?.value !== undefined ? formatInputValue(e, true) : null;
                              }}
                              minDate={startDate}
                              maxDate={getMaxEndDate()}
                              placeholderText="MM/DD/YYYY"
                              disabled={!startDate}
                              className="lg:max-w-[203px]"
                            />
                          </div>
                        </div>
                        {!notimeslotserror && (
                          <ButtonHelper
                            field={{
                              value: getDictionaryValue(
                                'ScheduleAppointmentsStep4FindAppointmentsButtonText'
                              ),
                            }}
                            isLinkfield={false}
                            size="medium"
                            variant="primary"
                            className="mt-[10px]"
                            onClickHandler={() => {
                              getNextAvailableSlotsByGroomer();
                            }}
                            isDisbaled={error != '' || endDateError !== ''}
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
                {/* --------------------------------- CHECK FOR GROOMERS ENDS HERE
            ------------------------------------ */}
                {/* ------------------------------------- SEARCH CRITERIA STARTS HERE ----------------------------------- */}
                {searchCriteria && !showSelectedAppointmentData && (
                  <div className="search-criteria-wrapper">
                    <div className="search-criteria-heading">
                      <span className={availableTimeNote()}>
                        {getDictionaryValue('ScheduleAppointmentsStep4SearchCriteriaLabel')}
                      </span>
                      <p
                        className={step1HeadingLink()}
                        onClick={() => {
                          setnextAvailableApp(true);
                          setsearchCriteria(false);
                          setnextAvailableApp(false);
                          setnextAvailableAppList(undefined);
                          setsaveUserConcent(false);
                          setShowSelectedAppointmentData(false);
                          setNoTimeslotsError(false);
                          // if (notimeslotserror) {
                          //   setSelectedOption('');
                          // }
                        }}
                      >
                        {getDictionaryValue('ScheduleAppointmentStep1EditLinkText')}
                      </p>
                    </div>
                    <div className="search-criteria-information">
                      <p>
                        {selectedTimeslot === 'Any'
                          ? 'Any Time Frame'
                          : selectedTimeslot === 'AM'
                          ? 'Morning Time'
                          : 'Afternoon/Evening Time'}
                      </p>
                      <p>
                        {selectedGroomerName === 'Any' || !selectedGroomerName
                          ? 'Any grooming professional'
                          : 'With' + ' ' + selectedGroomerName}
                      </p>
                      {startDate && (
                        <p>
                          {getDictionaryValue('scheduleAppointmentStartOn')}
                          {''}
                          {startDate}
                        </p>
                      )}
                      {endDate && (
                        <p>
                          {getDictionaryValue('scheduleAppointmentEndOn')}
                          {''}
                          {endDate}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                {/* ------------------------------------- SEARCH CRITERIA ENDS HERE ----------------------------------- */}
                {/* --------------------------- NEXT AVAILABLE APPOINTMENTS SECTION STARTS HERE ---------------------- */}
                {notimeslotserror && (
                  <div>
                    <div className={availableAppointementWrapper()}>
                      <span className={availableTimeNote()}>
                        {getDictionaryValue('scheduleAppointmentAvailableTimes')}
                      </span>
                    </div>
                    <p className="mt-[12px]">
                      {(() => {
                        const message = getDictionaryValue(
                          'ScheduleAppointmentsStep4NoAppointmentsMessage'
                        );
                        const parts = message.split('{0}');
                        return (
                          <>
                            {parts[0]}
                            <a href={`tel:${selectedStoreFromStep1?.phone}`}>
                              {selectedStoreFromStep1?.phone || ''}
                            </a>
                            {parts[1]}
                          </>
                        );
                      })()}
                    </p>
                  </div>
                )}

                {nextAvailableApp && (
                  <>
                    {showFetchAppointmentLoader && (
                      <LoaderComponent loaderText={'Fetching Appointments'} />
                    )}
                    {!showFetchAppointmentLoader && !showSelectedAppointmentData && (
                      <>
                        {!notimeslotserror && (
                          <div className={availableAppointementWrapper()}>
                            <span className={availableTimeNote()}>
                              {getDictionaryValue('scheduleAppointmentAvailableTimes')}
                            </span>
                          </div>
                        )}

                        <div className="availableAppointmentsWrapper">
                          {nextAvailableAppList !== undefined &&
                            (nextAvailableAppList.NoOpenDatesOrOpenTimeSlotsAvailable === true ? (
                              <p>
                                {getDictionaryValue(
                                  'ScheduleAppointmentsStep4NoAppointmentsMessage'
                                )}
                              </p>
                            ) : (
                              nextAvailableAppList.OpenDates.map(
                                (item: DaySchedule, index: number) => (
                                  <div key={index}>
                                    <div className={clsx('date-item')}>
                                      <span className={daysListing()}>
                                        {item.DayOfWeek + ', ' + item.DateDisplay}
                                      </span>
                                    </div>
                                    <div className="time-list flex flex-row gap-[12px] flex-wrap">
                                      {item.OpenTimes.map((time, timeIndex: number) => (
                                        <div
                                          key={timeIndex}
                                          className={clsx(
                                            timeItem(),
                                            'time-item',
                                            selectedTime === time &&
                                              'selected-time bg-color-text-brand-1 !text-color-background-white'
                                          )}
                                          onClick={() => {
                                            setSelectedTime(time);
                                            setTimeout(() => {
                                              const scrollToconsent =
                                                document.getElementsByClassName(
                                                  'user-consent-wrap'
                                                )[0];
                                              scrollToconsent &&
                                                scrollToconsent.scrollIntoView({
                                                  behavior: 'smooth',
                                                });
                                            }, 700);
                                            groomingStepsData({
                                              eventName: GTM_EVENT?.groomingSteps,
                                              storeId: myStoreData?.storeId,
                                              groomingStoreId: selectedStoreFromStep1?.storeid,
                                              stepName: GTMLabels?.groomingStep4StepName,
                                            });
                                          }}
                                        >
                                          {time.BeginAtTimeDisplayValue}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )
                              )
                            ))}
                        </div>

                        {showloadmore && !notimeslotserror && (
                          <>
                            {getMoreTimeSlotsLoader ? (
                              <LoaderComponent loaderText={'Fetching Appointments'} />
                            ) : (
                              <div>
                                <p
                                  className="mt-[20px] leading-body-large-bold  text-body-large-bold  text-color-text-brand-1 flex cursor-pointer items-center"
                                  onClick={() => getMoreTimeSlots()}
                                >
                                  {getDictionaryValue('ViewMore')}
                                  <IconHelper
                                    className="[&_svg_*]:fill-color-text-brand-1 ml-[4px] [&_svg]:w-4 [&_svg]:h-4"
                                    icon={'chevron-down'}
                                  />
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {showSelectedAppointmentData && (
                      <div className="search-criteria-information">
                        <p>
                          {selectedTime?.DateDisplayValue} at{' '}
                          {selectedTime?.BeginAtTimeDisplayValue} with{' '}
                          <span>
                            {selectedGroomerName === 'Any'
                              ? 'Any grooming professional'
                              : selectedTime?.Groomername}
                          </span>
                        </p>
                      </div>
                    )}

                    {selectedTime !== null && (
                      <>
                        <div className={finalStep()}>
                          <span className={clsx(step1HeadingTitle(), 'mt-[24px] block')}>
                            {getDictionaryValue('ScheduleAppointmentsStep4WhereCanWeReachYou')}
                          </span>
                          <span className={clsx(noteText(), 'mb-[12px] block')}>
                            {getDictionaryValue('ScheduleAppointmentsStep4RequiredFieldsMessage')}
                          </span>
                          <div className={collectConcent()}>
                            <div className={telephoneInfo()}>
                              <div>
                                <label>{getDictionaryValue('scheduleAppointmentTelephone')}</label>
                                <input
                                  aria-label="phoneNumber"
                                  className={`phone_us text-box single-line py-[12px] px-[8px] ml-[12px] bg-white ${
                                    error ? 'invalid' : 'valid'
                                  }`}
                                  type="text"
                                  id="phone"
                                  name="PhoneNumber"
                                  value={
                                    selectedContactOption === 'Mobile'
                                      ? phoneNumber !== ''
                                        ? formatPhoneNumber(phoneNumber) ||
                                          formatPhoneNumber(userDetails?.Phone)
                                        : ''
                                      : selectedContactOption === 'Home'
                                      ? formatPhoneNumber(homeNumber)
                                      : formatPhoneNumber(userDetails?.xp?.HomePhone)
                                  }
                                  onChange={(e) =>
                                    handleChange(
                                      e.target?.value,
                                      selectedContactOption === 'Mobile'
                                        ? setPhoneNumber
                                        : setHomeNumber
                                    )
                                  }
                                  placeholder={placeholder}
                                  maxLength={14}
                                />
                              </div>
                              {error && (
                                <span id="phone-error" className="error ml-[25px] text-pdp-red">
                                  {error}
                                </span>
                              )}
                            </div>
                            <div className="flex flex-row gap-[10px] mb-[12px]">
                              <div className="flex flex-row gap-[10px]">
                                <label htmlFor="Mobile" className="relative">
                                  <input
                                    aria-label="mobile"
                                    type="checkbox"
                                    name="Mobile"
                                    id="Mobile"
                                    value="Mobile"
                                    onChange={handleMobileCheckboxChange}
                                    checked={selectedContactOption === 'Mobile'}
                                    className={checkbox()}
                                  />
                                  {selectedContactOption === 'Mobile' && (
                                    <IconHelper
                                      className={checkboxIcon()}
                                      icon={'icon-checkmark'}
                                    />
                                  )}
                                </label>
                                <span>{getDictionaryValue('scheduleAppointmentMobile')}</span>
                              </div>
                              <div className="flex flex-row gap-[10px]">
                                <label htmlFor="Home" className="relative">
                                  <input
                                    aria-label="home"
                                    type="checkbox"
                                    name="Home"
                                    id="Home"
                                    value="Home"
                                    onChange={handleHomeCheckboxChange}
                                    checked={selectedContactOption === 'Home'}
                                    className={checkbox()}
                                  />
                                  {selectedContactOption === 'Home' && (
                                    <IconHelper
                                      className={checkboxIcon()}
                                      icon={'icon-checkmark'}
                                    />
                                  )}
                                </label>
                                <span>{getDictionaryValue('scheduleAppointmentHome')}</span>
                              </div>
                            </div>
                            <div className="save-to-profile flex flex-row gap-[10px] mb-[12px]">
                              <label htmlFor="saveToProfile" className="relative">
                                <input
                                  aria-label="saveToProfile"
                                  type="checkbox"
                                  name="saveToProfile"
                                  value="checked"
                                  onChange={() => {
                                    profileChecked();
                                  }}
                                  checked={saveToProfileChecked}
                                  className={checkbox()}
                                />
                                {saveToProfileChecked && (
                                  <IconHelper
                                    className={checkboxIcon()}
                                    icon={'icon-checkmark'}
                                    onClick={() => {
                                      profileChecked();
                                    }}
                                  />
                                )}
                              </label>
                              <span>
                                {' '}
                                {getDictionaryValue('ScheduleAppointmentsStep4SaveToProfileText')}
                              </span>
                            </div>
                            <div className="user-consent flex flex-row gap-[10px] mb-[12px]">
                              <label htmlFor="userConcent" className="relative">
                                <input
                                  aria-label="userConcent"
                                  type="checkbox"
                                  name="userConcent"
                                  value="checked"
                                  onChange={() => {
                                    checkConcent();
                                  }}
                                  className={checkbox()}
                                  checked={saveUserConcent}
                                />
                                {saveUserConcent && (
                                  <IconHelper
                                    className={checkboxIcon()}
                                    icon={'icon-checkmark'}
                                    onClick={() => {
                                      checkConcent();
                                    }}
                                  />
                                )}
                              </label>
                              <span>
                                {' '}
                                {getDictionaryValue(
                                  'ScheduleAppointmentsStep4TermsAndConditionsText'
                                )}
                              </span>
                            </div>
                            {}
                            <div className="schedule-appointmentWrapper flex flex-row gap-[12px]">
                              {bookAppointmentLoader && (
                                <LoaderComponent
                                  loaderText={getDictionaryValue(
                                    'ScheduleAppointmentsStep2SelectDogInProgressText'
                                  )}
                                ></LoaderComponent>
                              )}
                              {!bookAppointmentLoader && (
                                <ButtonHelper
                                  isDisbaled={!saveUserConcent || error !== ''}
                                  field={{
                                    value: getDictionaryValue(
                                      'ScheduleAppointmentsStep4ScheduleAppointmentButtonText'
                                    ),
                                  }}
                                  isLinkfield={false}
                                  size="medium"
                                  variant="primary"
                                  className={clsx(
                                    !saveUserConcent || error !== '' ? 'opacity-50' : 'opacity-100',
                                    'mt-[10px]'
                                  )}
                                  onClickHandler={() => {
                                    checkForUpdatedData();
                                  }}
                                />
                              )}
                              <ButtonHelper
                                field={{
                                  value: getDictionaryValue(
                                    'ScheduleAppointmentsStep4CancelButtonText'
                                  ),
                                }}
                                isLinkfield={false}
                                size="medium"
                                variant="secondary"
                                className="mt-[10px]"
                                onClickHandler={() => {
                                  setSelectedOption('');
                                  setsearchCriteria(false);
                                  setSelectedTimeslot('');
                                  setnextAvailableApp(false);
                                  setcheckforGroomers(false);
                                  setShowSelectedAppointmentData(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {showConfirmationPopup && (
                          <ModalWrapper
                            showCloseButtonInModalHeader={false}
                            closeModalOnOverlayClick={true}
                            onCloseClick={handleModalClose}
                            showModal={true}
                            customPopup={true}
                            popupWidth="max-w-[1200px]"
                            popupSpacing="p-[0px]"
                          >
                            <>
                              <div className={bookedAppointmentConfirmation()}>
                                <p>
                                  {getDictionaryValue(
                                    'scheduleAppointmentYourAppointmentForDogAt'
                                  )?.replace(
                                    'Dog',
                                    step2data?.Petname ||
                                      componentContextData?.bookedAppointmentForStep1?.ChildName ||
                                      step2data?.Name
                                  )}{' '}
                                  {selectedTime?.BeginAtTimeDisplayValue}{' '}
                                  {getDictionaryValue('scheduleAppointmentOn')}{' '}
                                  {selectedTime?.DateDisplayValue}{' '}
                                  {getDictionaryValue('scheduleAppointmentHasBeenConfirmed')}{' '}
                                </p>
                                <div className="confirmation-cta-wrapper flex lg:flex-row flex-col lg:items-center">
                                  <LinkHelper
                                    className={clsx(selectStoreCTA(), 'inline-block my-[12px]')}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      resetStateAndContext();
                                    }}
                                    field={{
                                      value: {
                                        href: '/',
                                        text: getDictionaryValue('Schedule Another'),
                                      },
                                    }}
                                  />
                                  <LinkHelper
                                    className={clsx('ml-[12px] text-color-text-brand-1')}
                                    field={{
                                      value: {
                                        href: appointmentConfirmationPopupContent
                                          ?.viewYourAppointments?.value?.href,
                                        text: appointmentConfirmationPopupContent
                                          ?.viewYourAppointments?.value?.text,
                                      },
                                    }}
                                  />
                                </div>
                                <div className="confirmation-image-wrapper">
                                  <ImageHelper
                                    className={confirmationImageWrapper()}
                                    field={{
                                      value: {
                                        src: appointmentConfirmationPopupContent?.successDialogImage
                                          ?.value?.src,
                                        alt: appointmentConfirmationPopupContent?.successDialogImage
                                          ?.value?.alt,
                                        width:
                                          appointmentConfirmationPopupContent?.successDialogImage
                                            ?.value?.width,
                                        height:
                                          appointmentConfirmationPopupContent?.successDialogImage
                                            ?.value?.height,
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          </ModalWrapper>
                        )}
                        {userDataUpdateWarning && (
                          <ModalWrapper
                            showCloseButtonInModalHeader={false}
                            closeModalOnOverlayClick={true}
                            onCloseClick={handleDataUpdateWarningModalClose}
                            showModal={true}
                            customPopup={true}
                            popupWidth="max-w-[1200px]"
                            popupSpacing="p-[0px]"
                          >
                            <div className={bookedAppointmentConfirmation()}>
                              <p className="font-heading-desk-xLarge-bold text-color-text-black text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold mb-[40px]">
                                {getDictionaryValue(
                                  'ScheduleAppointmentsStep4PhoneUpdateWarningHeading'
                                )}
                              </p>
                              <p>
                                {getDictionaryValue(
                                  'ScheduleAppointmentsStep4PhoneUpdateWarningMessage'
                                )}
                              </p>
                              <div className="confirmation-cta-wrapper flex flex-row gap-[12px] mt-[24px]">
                                <ButtonHelper
                                  field={{
                                    value: getDictionaryValue(
                                      'ScheduleAppointmentsStep4UpdatePhoneButtonText'
                                    ),
                                  }}
                                  isLinkfield={false}
                                  size="medium"
                                  variant={'primary'}
                                  onClickHandler={async () => {
                                    const ocUser = {
                                      ID: userDetails?.ID,
                                      Phone:
                                        selectedContactOption === 'Mobile'
                                          ? phoneNumber
                                          : userDetails?.Phone,
                                      xp: {
                                        HomePhone:
                                          selectedContactOption === 'Home'
                                            ? homeNumber
                                            : userDetails?.xp?.HomePhone,
                                      },
                                    };
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    await dispatch(updateUser(ocUser as any));
                                    setDonotUpdateUser(false);
                                    schdueleAppointment();
                                  }}
                                />

                                <ButtonHelper
                                  field={{
                                    value: getDictionaryValue(
                                      'ScheduleAppointmentsStep4DoNotUpdateButtonText'
                                    ),
                                  }}
                                  isLinkfield={false}
                                  size="medium"
                                  variant="secondary"
                                  onClickHandler={() => {
                                    setDonotUpdateUser(true);
                                    schdueleAppointment();
                                  }}
                                />
                              </div>
                            </div>
                          </ModalWrapper>
                        )}
                      </>
                    )}
                  </>
                )}
                {/* --------------------------- NEXT AVAILABLE APPOINTMENTS SECTION ENDS HERE ---------------------- */}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ScheduleAppointmentStep4;
